<script>
  import { Route, Router } from "svelte-routing";

  import Footer from "./components/Footer.svelte";
  import HeaderInjector from "./components/HeaderInjector.svelte";
  import LogoBar from "./components/LogoBar.svelte";
  import NavBar from "./components/NavBar.svelte";

  import { routesByPath } from "./routes";
  const routes = Object.values(routesByPath).filter(
    (o) => o.path && o.component
  );

  // Used for SSR. A falsy value is ignored by the Router.
  export let url = "";

  // Ensure all external links generated from mdown open in a new window
  import marked from "marked";
  import { onMount } from "svelte";
  const renderer = new marked.Renderer();
  renderer.link = function (href, title, text) {
    if (href.startsWith("http:///")) href = href.slice(7);
    else if (href.startsWith("https:///")) href = href.slice(8);
    const link = marked.Renderer.prototype.link.call(this, href, title, text);
    if (href.startsWith("/") || href.startsWith("#")) return link;
    return link.replace("<a", '<a rel="noopener" target="_blank"');
  };
  marked.use({ renderer });

  // Embed code from tawk.to
  onMount(() => {
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = "https://embed.tawk.to/675036644304e3196aec575e/1ie8l8u85";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();
  });
</script>

<svelte:head>
  <!-- Parsing -->
  <meta charset="UTF-8" />
  <meta http-equiv="X-UA-Compatible" content="IE=Edge" />

  <!-- Responsiveness -->
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />

  <!-- Resources -->
  <link rel="stylesheet" href="/css/bundle.css" />
  <script defer src="/js/bundle.js">
  </script>
  <!-- Google Analytics -->
  <!-- Google Analytics -->
  <!-- Google Analytics -->
  <script
    async
    src="https://www.googletagmanager.com/gtag/js?id=UA-135521671-1"
  >
  </script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "UA-135521671-1");
  </script>
</svelte:head>

<LogoBar />

<NavBar {url} />

<Router {url}>
  {#each routes as route}
    <Route path={route.path} let:params>
      <HeaderInjector id={params.id} {route} />
      <svelte:component this={route.component} id={params.id} {route} />
      <Footer {route} />
    </Route>
  {/each}
</Router>

<style lang="scss" global>
  @import "global.scss";
</style>
