// TODO: add any missing alts for images in folder

const altsByName = {
  abstract_x68e: "person inserting the last piece of an abstract puzzle",
  ask_me_anything_k8o0: "person in front of a series of abstract social posts",
  connection_b38q: "people connecting from separate boxes",
  destinations_fpv7: "person mapping out the key parts of a journey line",
  dev_focus_b9xo: "person working at a laptop thinking about checklists",
  experience_design_eq3j: "minions assembling a design - one on another's shoulders",
  faq_rjoy: "people standing by a large question mark",
  feeling_of_joy_re_cqko: "person holding up a placard with a smiley face on it",
  files1_9ool: "document stack",
  hang_out_h9ud: "four people in conversation at a table",
  hire_te5y: "person looking at professional profiles",
  in_thought_gjsf: "person orbited by thoughts",
  journey_lwlj: "person holding a passport in front of mountains with airplane",
  joyride_hnno: "person riding a spacehopper",
  map_light_3hjy: "map with multiple waypoints",
  multitasking_hqg3: "person working at a desk",
  neighbors_ciwb: "people chatting with each other leaning out of windows",
  page_not_found_su7k: "person sitting on a giant 404",
  people_search_wctu: "person looking carefully at profiles",
  process_e90d: "person looking at inter-related documents",
  programmer_imem: "person sitting in front of multiple screens",
  quitting_time_dm8t: "person in front of desk on their phone",
  remotely_2j6y: "person working at a desk",
  result_5583: "person leaning on a product map",
  team_spirit_hrr4: "people chatting about work",
  true_love_cy8x: "minions holding hands in front of mountains with a heart as the sun",
  video_call_kxyp: "people on a video call",
};

export function undrawImageAlt(name) {
  return altsByName[name] ||
    name.split("_").slice(0, -1).join(" ");
}
