<script>
  import { faHeart } from "@fortawesome/free-solid-svg-icons";

  import Container from "../components/Container.svelte";
  import Icon from "../components/Icon.svelte";
  import NavLink from "../components/NavLink.svelte";
  import Section from "../components/Section.svelte";

  import Email from "../components/share/Email.svelte";
  import Facebook from "../components/share/Facebook.svelte";
  import LinkedIn from "../components/share/LinkedIn.svelte";
  import Twitter from "../components/share/Twitter.svelte";

  const hashtags = "communication,collaboration,culture";
  const subject = "PeopleStorming Community";
  const text =
    "I just signed up for @PeopleStorming's community newsletter about communication, collaboration and culture. They send out tips, tools and inspiration on these topics.";
  const textEmail =
    "I just signed up for PeopleStorming's community newsletter about communication, collaboration and culture. They send out tips, tools and inspiration on these topics. Thought you might be interested:";
  const urlStub = "https://www.peoplestorming.com/signup";
</script>

<Section bgColor="dark-grey">
  <Container>
    <h1>Welcome To Our Community</h1>
    <p class="strapline">
      Tips, tools and inspiration will soon appear in your inbox
    </p>
    <img alt="hooray" height="225" src="/img/hooray.gif" width="400" />
    <p class="strapline">
      While you're waiting, why not check out <NavLink
        style="text-decoration-line:underline"
        to="/blog"
      >
        our blog
      </NavLink>
    </p>
  </Container>
</Section>

<Section>
  <Container>
    <h2>Sharing Is Caring</h2>
    <p>Know someone else who might want to join our community?</p>
    <p class="spread">
      Share the <Icon i={faHeart} /><Icon i={faHeart} /><Icon i={faHeart} />
    </p>
    <Facebook url={urlStub + "?psst-fb"} />
    <Twitter {text} url={urlStub + "?psst-tw"} {hashtags} />
    <LinkedIn url={urlStub + "?psst-li"} />
    <Email {subject} body="{textEmail} {urlStub}?psst-em" />
  </Container>
</Section>

<style>
  img {
    margin-bottom: 1rem;
  }

  p.spread :global(.icon) {
    color: palevioletred;
  }
</style>
