<script>
  import Container from "../components/Container.svelte";
  import Quote from "../components/Quote.svelte";
  import Section from "../components/Section.svelte";
  import { undrawImageAlt } from "../undrawImageAlts";

  let innerHeight;
</script>

<svelte:window bind:innerHeight />

<Section bgColor="dark-grey">
  <Container>
    <img
      alt={undrawImageAlt("bibliophile_hwqc")}
      class="head mb1r"
      height="778"
      src="/img/illustrations/gold/undraw_mindfulness_scgo.svg"
      width="814"
    />
    <h1>Burnout Guide</h1>
    <p class="strapline">
      A free, three-part guide to understanding, preventing and managing burnout
    </p>
  </Container>
</Section>

<Section bgColor="jet" noPad>
  <Container>
    <div class="columns is-centered">
      <div class="column is-two-thirds">
        <Quote
          citation="Lauren S. - Customer Success Manager - intelliHR"
          paras={[
            "This resource is awesome! A really good guide to burnout from an employee and organisational point of view. It's different to others I've seen because it looks at 'shadow stressors' that contribute to burnout beyond just workload.",
          ]}
        />
      </div>
    </div>
  </Container>
</Section>

<Section>
  <Container>
    <div class="columns">
      <div class="column is-two-thirds description">
        <h2>Why does it matter?</h2>
        <p>
          According to a recent Gallup study, "76% of employees experience
          burnout on the job at least sometimes, and 28% say they are burned out
          very often or always at work". The study also discovered that it was
          how individuals experience their workload that made the most
          significant impact on their wellbeing.
        </p>
        <p>
          Burnout is more than simply stress or a bad week at work. It's not
          just feeling tired - sleeping doesn't solve it. Someone experiencing
          burnout might feel a combination of exhaustion and anxiety; a sense
          that they need to keep going whilst also needing to rest. Then, when
          they try to rest, they cannot switch off. When they try to work, they
          cannot focus or concentrate. Even small, simple things can seem
          overwhelming.
        </p>
        <p>Our problems with burnout need urgent attention.</p>
      </div>

      <div class="column is-one-thirds color-bg-platinum summary">
        <h2>The guide includes...</h2>
        <ul>
          <li>the 4 key indicators of burnout</li>
          <li>a set of powerful coaching questions</li>
          <li>prevention and management tips for individuals</li>
          <li>
            burnout-resistance questions for hiring managers and candidates
          </li>
        </ul>
      </div>
    </div>
  </Container>
</Section>

<style>
  img.head {
    max-height: 200px;
  }

  .description,
  .summary {
    text-align: left;
  }

  .summary {
    border-radius: 5px;
  }

  .summary li {
    margin-bottom: 0.5rem;
    margin-left: 1.5rem;
  }

  .summary ul {
    list-style-type: circle;
  }
</style>
