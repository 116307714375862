<script>
  import AirtableSubscribe from "../components/AirtableSubscribe.svelte";
  import Container from "../components/Container.svelte";
  import Section from "../components/Section.svelte";
  import { undrawImageAlt } from "../undrawImageAlts";

  const tools = [
    {
      image: "personal_notebook_re_d7dc",
      title: "Personal User Manual Workbook",
      text: "This tool will help you share your work style and preferences for a smoother, more collaborative working experience.",
      url: "https://docs.google.com/presentation/d/1HFI5q_ITO_pHrgksg8onYkox7BXhibpPQqW0GtYjFBI",
    },
    {
      image: "detailed_information_3sp6",
      title: "G.R.O.W. Coaching Questions",
      text: "This tool will kickstart your coaching skills using questions designed to help you move the conversation through the four stages of coaching.",
      url: "/downloads/coaching-grow-model.pdf",
    },
    {
      image: "faq_rjoy",
      title: "Powerful Question Framework",
      text: "This tool will help you formulate a powerful question for a meeting or workshop where you need participants to think boldly. It can also be used to launch a coaching conversation.",
      url: "/downloads/powerful-question-framework.pdf",
    },
    {
      image: "personal_info_0okl",
      title: "Persona Worksheet",
      text: "This design-thinking inspired tool can help you better understand your customers, team members and candidates.",
      url: "/downloads/persona-sheet.pdf",
    },
    {
      image: "tasks_re_v2v4",
      title: "Urgency Ranking Tool",
      text: "This worksheet will help you think through how the urgency of a task/project changes over time.",
      url: "https://docs.google.com/document/d/1B3WLgQo2IwVnXItjX2l5792Wh8DSfS-LFTEZdK69opg/edit#heading=h.xrarejj0b1",
    },
    {
      image: "bibliophile_hwqc",
      title: "Burnout Guide",
      text: "A three-part guide to understanding, preventing and managing burnout with sections aimed at individuals and organizations respectively.",
      url: "/downloads/burnout-guide.pdf",
    },
    {
      image: "fish_bowl_uu88",
      title: "Stinky Fish Worksheet",
      text: "This tool will help identify challenges and problems at the start of any team project.",
      url: "/downloads/stinky-fish-canvas.pdf",
    },
  ];

  for (let tool of tools) {
    let titleWords = tool.title.split(" ");
    let noun = titleWords[titleWords.length - 1].toLowerCase();
    let isPlural = noun.endsWith("s");
    tool.cta = `Get ${isPlural ? "these" : "this"} ${noun}`;
    tool.download = tool.url.endsWith(".pdf") ? "" : null;
  }

  export let route;
  $: fulfill = route.path == "/tools-fulfill";
</script>

<Section bgColor="dark-grey">
  <Container>
    <img
      alt={undrawImageAlt("files1_9ool")}
      class="head mb1r"
      height="778"
      src="/img/illustrations/gold/undraw_files1_9ool.svg"
      width="814"
    />
    <h1>Tools For Subscribers</h1>
    <p class="strapline">
      Get access to these {tools.length} free tools by joining our community
    </p>
    <AirtableSubscribe color="gold" cta="Get access" size="medium" />
  </Container>
</Section>

<Section>
  <Container>
    <div class="columns is-multiline">
      {#each tools as tool}
        <div class="column is-one-third">
          <img
            alt={undrawImageAlt(tool.image)}
            class="mb1r"
            src="/img/illustrations/gold/undraw_{tool.image}.svg"
            style="height:150px"
          />
          <h3>{tool.title}</h3>
          <p>{tool.text}</p>
          {#if fulfill}
            <a
              class="button is-gold"
              download={tool.download}
              href={tool.url}
              rel="noopener"
              target={tool.download == null ? "_blank" : "_self"}
            >
              {tool.cta}
            </a>
          {/if}
        </div>
      {/each}
    </div>
  </Container>
</Section>

<style>
  a.button {
    margin-bottom: 1rem;
  }

  img.head {
    max-height: 200px;
  }
</style>
