<script>
  import {
    faFrown,
    faMehRollingEyes,
    faPeopleArrows,
    faStar,
    faTired,
  } from "@fortawesome/free-solid-svg-icons";
  import QueryString from "query-string";
  import { flip } from "svelte/animate";
  import { fade, fly } from "svelte/transition";

  import Container from "../components/Container.svelte";
  import Icon from "../components/Icon.svelte";
  import Section from "../components/Section.svelte";

  const journeys = {
    // survey -> results (descriptions, no tips) -> CTAs (book signup, disco call for team wkshp, reg for solo wkshp)
    j1: {
      showDescriptions: true,
      showTips: false,
      ctaBook: true,
      ctaWorkshop: true,
      referBook: false,
      referWorkshop: false,
    },
    // book -> survey -> results (descriptions, no tips) -> "guidance in book" + CTAs (disco-teams, reg-solo)
    j2: {
      showDescriptions: true,
      showTips: false,
      ctaBook: false,
      ctaWorkshop: true,
      referBook: false, // switched this off as it was challenging to lay it out between the results panel and the workshop CTA
      referWorkshop: false,
    },
    // workshop -> survey -> results (no descriptions, no tips)
    j3: {
      showDescriptions: false,
      showTips: false,
      ctaBook: false,
      ctaWorkshop: false,
      referBook: false,
      referWorkshop: false,
    },
    // pre-workshop -> survey -> results (no descriptions, no tips) -> "we'll explore meaning and guidance in wkshp"
    j4: {
      showDescriptions: false,
      showTips: false,
      ctaBook: false,
      ctaWorkshop: false,
      referBook: false,
      referWorkshop: true,
    },
  };

  let journey = journeys.j1;
  if (typeof window !== "undefined") {
    const qJourneys = Object.keys(QueryString.parse(window.location.search));
    for (const j in journeys) {
      if (qJourneys.includes(j)) {
        journey = journeys[j];
        break;
      }
    }
  }

  const answers = [
    "Never",
    "A few times a year",
    "Once a month or less",
    "A few times a month",
    "Once a week",
    "A few times a week",
    "Every day",
  ];

  const colors = [
    "#f7322c",
    "#f36e24",
    "#f8a62a",
    "#ffcf59",
    "#97d91a",
    "#56ca1d",
    "#1fbc1f",
  ].reverse();

  const sections = [
    {
      name: "Exhaustion",
      icon: faTired,
      statements: [
        "I feel exhausted towards the end of the day",
        "I feel tired when I wake up in the morning",
        "I feel emotionally wrung-out after a day at work",
        "I feel like I don't have the energy to start basic tasks",
        "I put off meeting friends or family after work because I'd prefer just to rest",
        "I feel too exhausted to participate in activities that used to bring me joy",
        "I feel a sense of emptiness inside",
      ],
      description:
        "Exhaustion isn't just an upgraded version of fatigue. After all, everyone occasionally experiences feeling tired during the day. Exhaustion is the complete bottoming-out of our energy reserves. It leaves us barely able to move or think. It's the fatigue that comes from caring too much for too long.",
      tips: [
        "Make sure you are leveraging your social network - family, friends and colleagues - to share your experience. Regularly talking about challenges can help you feel less alone and more able to cope.",
        "Take regular breaks during the day and ensure you are taking some longer breaks - entire days off or weeks off as needed. Speak to your manager if you're hesitant to take time off - they can coach you through the concerns.",
        "Reflect on the situations that cause you the most negative feelings. Are there issues that you've been procrastinating on or conflicts that need resolving? Carrying that baggage around weighs you down and diminishes your energy. Seek to resolve those conflicts in a healthy manner with help from your support network.",
        "Think about where you might add in some exercise. Doing something you enjoy and that gets you back into your body again can be powerful. Even though it can be hard to find the energy for a workout, some physical activity will provide you with renewed energy and mood stabilization. If you can only manage 5 minutes, that's a great start. YouTube has tons of resources.",
        "Look at whether you are getting regular and sufficient sleep. High quality sleep has a big impact. Try developing a bedtime routine to set yourself up for success.",
        "Begin building a practice of mindfulness until you are spending at least a few mindful minutes every day. Check out the Calm, Balance and Headspace apps.",
        "If you sense that your wellbeing is sufficiently at risk, consider reaching out to your PCP or a mental healthcare provider.",
      ],
    },
    {
      name: "Cynicism",
      icon: faMehRollingEyes,
      statements: [
        "I think that my work is ultimately valueless",
        "I feel like I'm not going to grow or develop at work",
        "If I were to quit tomorrow, it wouldn't have any real impact",
        "Even though I've been successful in the past, I see mostly failure in my future",
      ],
      description:
        "In a professional context, cynicism looks like a persistent belief that (regardless of how competent you are) work will either fail or result in an output that is of little ultimate value. In this way, it makes the workplace feel harsher and more challenging than it is. It also causes the sufferer to treat the intentions of others with suspicion, making collaboration harder.",
      tips: [
        "Is there something about the system you're operating in that needs to change? Look for broken feedback loops or cultural scar tissue.",
        "Think back to a time when the things you delivered at work felt more valuable to you. Identify 1-3 things that were different back then.",
        "Do you need to reconnect with the customers of your work (internal or external)? Go find a beneficiary of your work and ask them to describe its value to them.",
        "Have you convinced yourself that either the long- or short-term value of what you're delivering is the only ‘valid' time horizon to think about? Consider the opposite end of that spectrum.",
        "Failure can be extremely valuable when it fosters learning. Make sure you're seeing that value, too.",
        "Life isn't just about the destination. Being laser-focused on outcomes can cause you to ignore the value of the steps you take to achieve them. Remember to enjoy the journey as well.",
        "Try writing down your cynical predictions and then reflecting back on them once events have unfolded. Were you right?",
        "Begin building a practice of mindfulness until you are spending at least a few mindful minutes every day. Check out the Calm, Balance and Headspace apps.",
        "If you sense that your wellbeing is sufficiently at risk, consider reaching out to your PCP or a mental healthcare provider.",
      ],
    },
    {
      name: "Depersonalization",
      icon: faPeopleArrows,
      statements: [
        "I've become much less enthusiastic about my work overall",
        "I feel like I've become more dismissive towards my co-workers or clients",
        "I don't really take an interest in what happens in the life or work of the people I work with",
        "I perform my daily tasks with less passion than I used to have",
        "I find myself less concerned about whether people are satisfied with my work",
      ],
      description:
        "Defined as an emotional detachment from your work and from other people (especially co-workers or clients). Where you once cared about the work and your colleagues, you now don't have the capacity to do so and feel some level of indifference. It's the depletion of empathy, caring and compassion. Very often, burnout sufferers employ detachment as a defence mechanism against how they're feeling. They disconnect from authentic feelings about colleagues and their work. They can start to seem callous and disinterested.",
      tips: [
        "Try to identify situations where you feel particularly emotionally detached from your job - is there a specific trigger you notice? Reflect and see if there is something that can be changed about it in future.",
        "Create a list of your daily tasks. Categorize them into two buckets - things that bring you satisfaction and things that don't. Reflect on the ones that still bring you satisfaction. How can you spend more time on those?",
        "Think about the balance and blend of your day. Do you have enough balance between things that cause you negative feelings and things that cause you positive feelings?",
        "Think back to when you applied for the role you're in. How did you feel when you were offered it? What made you excited? Reconnect with your 'why' as much as possible.",
        "Think about finding a mentor inside or outside of work. This should be someone who can help you reconnect with your passion for life (both in being and in doing).",
        "Begin building a practice of mindfulness until you are spending at least a few mindful minutes every day. Check out the Calm, Balance and Headspace apps.",
        "If you sense that your wellbeing is sufficiently at risk, consider reaching out to your PCP or a mental healthcare provider.",
      ],
    },
    {
      name: "Professional Inefficacy",
      icon: faFrown,
      statements: [
        "I feel I am not meeting other people's expectations of me at work",
        "I am not effectively managing problems that come up with my work",
        "Even when I work hard, I'm not as efficient as I could be",
        "I don't feel like I'm good at my job",
        "I procrastinate on tasks until it's too late to deliver their results",
        "I don't take as much initiative as I used to (asking for new projects, engaging in meeting conversations)",
        "I feel like everyone else is more skilled and capable than me",
        "I'm struggling to complete my projects in a timely manner",
      ],
      description:
        "This is described as the feeling of being incompetent and being unable perform to your usual standards. It's a change in self-perception. It is the feeling that you cannot perform adequately to solve a task. It's not necessarily that your abilities have changed, it's that your sense of confidence has gotten lost in the mix. This state makes sufferers considerably more prone to ‘spiraling out' when asked to perform a task where they happen to have lesser skills or knowledge.",
      tips: [
        "What are the stories you are telling yourself on a day to day basis? Can you notice them and write them down? How might you validate or invalidate those stories?",
        "What new stories could you try telling yourself?",
        "Think about asking your team for feedback so you have some external input into the ways you are succeeding and the places you are still learning.",
        "Set yourself a clear goal for each day - one thing you want to achieve and start the day with that if you can. Feelings of progression and achievement are an important part of seeing yourself as worthy again.",
        "Is there a specific area where you genuinely need to grow? Ask your manager about getting some additional support - book recommendations, an online course etc.",
        "Make a list of all your work achievements and all the positive feedback you've received - keep it somewhere handy, so when you have a dark moment, you can look at it as a reminder of what's good.",
        "Begin building a practice of mindfulness until you are spending at least a few mindful minutes every day. Check out the Calm, Balance and Headspace apps.",
        "If you sense that your wellbeing is sufficiently at risk, consider reaching out to your PCP or a mental healthcare provider.",
      ],
    },
  ];

  let sectionId = 0;
  let statementId = 0;

  let progress = 1;
  const progressMax = sections.reduce(
    (sum, sec) => sum + sec.statements.length,
    0
  );

  const responses = [];
  let showResult = false;
  let showResultReady = false;

  const secLengthsById = Object.fromEntries(
    sections.map((sec, i) => [i, sec.statements.length])
  );

  function onRespond(rating) {
    responses.push([sectionId, statementId, rating]);

    statementId += 1;
    if (statementId >= secLengthsById[sectionId]) {
      sectionId += 1;
      statementId = 0;
    }

    if (sectionId >= sections.length) analyzeResponses();

    progress += 1;
  }

  const analysis = [];

  function analyzeResponses() {
    for (const i in sections) {
      const sec = sections[i];

      const { name } = sec;

      const total = responses
        .map(([sectionId, _, rating]) => (sectionId == i ? rating : 0))
        .reduce((sum, r) => sum + r, 0);

      const max = secLengthsById[i] * (answers.length - 1);

      const ratio = total / max;
      const summary = ratio < 0.4 ? "low" : ratio < 0.7 ? "moderate" : "high";

      const klass = {
        low: "success",
        moderate: "warning",
        high: "danger",
      }[summary];

      analysis.push({
        klass,
        max,
        name,
        ratio,
        summary,
        total,
      });
    }

    showResult = true;
  }

  let jumpName = null;
  function jumpDown(e) {
    jumpName = e.target.href.split("#")[1].replace("%20", " ");
    setTimeout(() => (jumpName = null), 1000);
  }
</script>

<Section bgColor="dark-grey">
  <Container>
    <h1>Burnout Assessment</h1>
    <p>
      This informal self-assessment will help you think about which burnout
      indicators might be most prevalent for you.
    </p>
    <p>
      <strong>This isn't a medical diagnosis or advice tool.*</strong>
    </p>
  </Container>
</Section>

{#if !showResult}
  <Section>
    <Container>
      <progress
        class="progress is-small is-warning overall"
        value={progress}
        max={progressMax}
      >
        {progress}/{progressMax}
      </progress>
      {#each [sections[sectionId]] as section (sectionId)}
        <div
          in:fade={sectionId > 0 ? { duration: 500 } : { duration: 0 }}
          out:fade={sectionId == sections.length - 1
            ? { duration: 500 }
            : { duration: 0 }}
          on:outroend={() => (showResultReady = showResult)}
        >
          <h2>
            Part {sectionId + 1}: {section.name}
          </h2>

          {#each [section.statements[statementId]] as statement (statementId)}
            <div
              animate:flip={statementId > 0}
              in:fly={statementId > 0
                ? { x: 200, duration: 500 }
                : { duration: 0 }}
              out:fly={statementId > 0 &&
              statementId < secLengthsById[sectionId] - 1
                ? { x: -200, duration: 500 }
                : { duration: 0 }}
              class="step"
            >
              <p class="statement">{statement}...</p>
              <table class="rating">
                {#each answers as answer, i}
                  <tr on:click={() => onRespond(i)}>
                    <td class="star" style="color:{colors[i]}; opacity:0.3">
                      <Icon i={faStar} />
                    </td>
                    <td>
                      {answer}
                    </td>
                  </tr>
                {/each}
              </table>
            </div>
          {/each}
        </div>
      {/each}
    </Container>
  </Section>
{/if}

{#if showResultReady}
  <Section>
    <Container>
      <div in:fade={{ duration: 500 }}>
        <a name="results">
          <h2>Results</h2>
        </a>
        <table class="results color-bg-mid-grey">
          {#each analysis as a}
            <tr>
              {#if journey.showDescriptions}
                <td><a href="#{a.name}" on:click={jumpDown}>{a.name}</a></td>
              {:else}
                <td>{a.name}</td>
              {/if}
              <td>
                <p class="has-text-{a.klass}">
                  {a.summary.charAt(0).toUpperCase() + a.summary.slice(1)}
                </p>
                <progress
                  class="progress is-small is-{a.klass}"
                  value={a.total}
                  max={a.max}
                >
                  {a.total}/{a.max}
                </progress>
              </td>
            </tr>
          {/each}
        </table>
      </div>
    </Container>

    {#if journey.referBook || journey.referWorkshop}
      <Container>
        <div class="columns is-centered">
          <div class="column is-two-thirds refer-book">
            {#if journey.referBook}
              <p>
                For guidance on what you might do next, refer back to the
                Burnout Guide.
              </p>
            {:else}
              <p>
                Make a <strong>note</strong> or take a
                <strong>screenshot</strong> of your results.
              </p>
              <p>We'll explore these indicators during the workshop.</p>
            {/if}
          </div>
        </div>
      </Container>
    {/if}

    {#if journey.ctaBook || journey.ctaWorkshop}
      <Container innerPad>
        <div class="columns is-centered">
          {#if journey.ctaBook}
            <div class="column is-one-third color-bg-platinum">
              <h3>Burnout Guide</h3>
              <p>
                Learn all about these indicators and what you can do about them.
              </p>
              <a class="button" href="/burnout-guide" target="_blank">
                Download the guide
              </a>
            </div>
          {/if}
          {#if journey.ctaWorkshop}
            <div
              class="column color-bg-light-gold"
              class:is-one-third={journey.ctaBook}
              class:is-two-thirds={!journey.ctaBook}
            >
              <h3>Burnout Workshop</h3>
              <p>Check out our workshop that will help you go deeper.</p>
              <a class="button is-dark" href="/w/wellbeing" target="_blank">
                See the workshop
              </a>
            </div>
          {/if}
        </div>
      </Container>
    {/if}

    {#if journey.showDescriptions}
      {#each sections as section, i}
        <Container>
          <div class="columns is-centered">
            <div
              class="column is-two-thirds definition"
              class:flashGold={section.name == jumpName}
            >
              <a name={section.name}>
                <h3><Icon i={section.icon} /> {section.name}</h3>
              </a>
              <p>
                {section.description}
              </p>
              <!-- <ul>
              {#each section.tips as tip}
                <li>{tip}</li>
              {/each}
            </ul> -->
              <p class="back-to-top">
                <a href="#results">back to results</a>
              </p>
            </div>
          </div>
        </Container>
      {/each}
    {/if}
  </Section>
{/if}

<Section sidePad>
  <Container>
    <div class="columns is-centered">
      <div class="column is-two-thirds">
        <p id="disclaimer">
          * This tool hasn't been validated through controlled, scientific
          testing. By it's very nature, it also necessarily contains statements
          that can be uncomfortable for some people. The spirit of it is to give
          you an idea of where you might prioritize your focus in order to
          reduce your burnout risk. Please interpret the results with a healthy
          dose of common sense. Also note that we do not collect any of your
          responses / results - none of that data ever leaves your web browser.
        </p>
      </div>
    </div>
  </Container>
</Section>

<style>
  .step {
    border: 1px solid var(--ps-colors-light-grey);
    border-radius: 5px;
    margin: 0 auto;
    max-width: 600px;
    padding: 1rem;
  }

  .step .statement {
    font-weight: bold;
  }

  .rating {
    margin: 0 auto;
  }

  .rating td {
    padding: 10px 5px;
    text-align: left;
    text-decoration: underline;
    text-decoration-color: var(--ps-colors-light-grey);
  }

  .rating tr:hover * {
    cursor: pointer;
    font-weight: bold;
    opacity: 1 !important;
  }

  .results {
    border-radius: 5px;
    margin: 0 auto 1.5rem;
  }

  .results td {
    padding: 10px;
    text-align: left;
  }

  .results td p {
    margin-bottom: 0.5rem;
  }

  #disclaimer {
    font-size: var(--ps-fontsize-tiny);
  }

  .definition {
    align-items: flex-start;
    text-align: left;
  }

  .definition h3 {
    margin-bottom: 1.5rem;
  }

  .definition h3 :global(.icon) {
    color: var(--ps-colors-gold);
    margin-right: 5px;
  }

  /* .definition li {
    margin-bottom: 0.5rem;
    margin-left: 1.5rem;
  }

  .definition ul {
    list-style-type: circle;
    margin-bottom: 1rem;
  } */

  a {
    text-decoration: underline;
    text-decoration-color: var(--ps-colors-light-grey);
  }

  a:hover {
    text-decoration-color: var(--ps-colors-gold);
  }

  a.button {
    text-decoration: none;
  }

  a[name] {
    cursor: inherit;
    text-decoration: none;
  }

  h2 {
    font-size: var(--ps-fontsize-medlarge);
    margin-bottom: 1.5rem;
  }

  progress.overall {
    margin: 0 auto 1.5rem;
    width: revert;
  }

  @keyframes bgBounceThroughGold {
    0% {
      background-color: inherit;
    }
    50% {
      background-color: var(--ps-colors-gold);
    }
    100% {
      background-color: inherit;
    }
  }

  .flashGold {
    animation: bgBounceThroughGold 1s;
  }
</style>
