<script>
  export let id;
  // export let style = "";
  // export let title = "Mural Board";

  let src = `https://app.mural.co/embed/${id}`;
  let staticSrc = `/img/mural/${id}.png`;
</script>

<!-- <div class="is-hidden-mobile" {style}>
  <iframe
    allowfullscreen=""
    frameborder="0"
    height="100%"
    sandbox="allow-same-origin allow-scripts allow-modals allow-popups allow-popups-to-escape-sandbox"
    {src}
    {title}
    width="100%"
  />
  <p>Left-click to drag canvas. Scroll to zoom in / out.</p>
</div>

<div class="is-hidden-tablet">
  <a aria-label="download mural board" href={staticSrc}>
    <img alt="mural board" src={staticSrc} />
  </a>
  <p>
    On bigger screens, this board is interactive. Click / tap to download a
    copy.
  </p>
</div> -->

<div>
  <a aria-label="download mural board" href={staticSrc}>
    <img alt="mural board" src={staticSrc} />
  </a>
  <p>Click / tap to download a copy.</p>
</div>

<style>
  div {
    margin-bottom: 2rem;
    width: 100%;
  }

  /* div.is-hidden-mobile {
    height: 480px;
    margin-bottom: 3rem; /* TODO: this is a hack due to the absolute sizing of the div
  } */

  p {
    display: block;
    font-size: var(--ps-fontsize-tiny);
    margin-top: 5px;
  }
</style>
