<script>
  import { onMount } from "svelte";
  import { airtableData } from "../airtable/compiled";
  import Container from "../components/Container.svelte";
  import NavLink from "../components/NavLink.svelte";
  import Section from "../components/Section.svelte";
  import TestimonialNav from "../components/TestimonialNav.svelte";

  let testimonials = airtableData.testimonials.filter((t) =>
    t.pagePaths[0].endsWith("/coaching-tech-refs")
  );

  onMount(() => {
    let seqs = [];

    for (let t of testimonials.toSorted(() => Math.random() - 0.5)) {
      let s = seqs[t.seq];
      if (s) s.push(t);
      else seqs[t.seq] = [t];
    }

    testimonials = seqs.flat();
  });
</script>

<Section bgColor="dark-grey">
  <Container>
    <h1>Why Us?</h1>
    <p class="strapline">
      Our coaching clients from the tech industry love us...
    </p>
  </Container>
</Section>

<Section>
  <Container>
    <TestimonialNav items={testimonials} subset={"coaching"} />
    <NavLink button buttonClass="is-gold" to="/book-call">
      Book a coaching call
    </NavLink>
  </Container>
</Section>
