<script>
  import marked from "marked";

  import AudioPlayer from "./AudioPlayer.svelte";
  import LeadProgAdaptCta from "./LeadProgAdaptCta.svelte";
  import LeadProgTable from "./LeadProgTable.svelte";
  import MuralBoard from "./MuralBoard.svelte";
  import Quote from "./Quote.svelte";

  export let content;
  export let dlButtonClass = "";
  export let dlButtonLabel = "Download info";
  export let postProcess = (h) => h;
  export let style = "";
  export let styleStrongWhite = false;
  export let testimonials = [];

  $: parts = content.split("!!!").map((part) => {
    let audioMatch = part.match(/AUDIO: (.+)/);
    if (audioMatch) {
      return { kind: "audio", data: "/audio/" + audioMatch[1] };
    }

    let dlMatch = part.match(/DOWNLOAD: (.+)/);
    if (dlMatch) {
      return { kind: "download", data: "/downloads/" + dlMatch[1] };
    }

    let lpacMatch = part.match(/LEADPROGADAPTCTA/);
    if (lpacMatch) {
      return { kind: "leadprogadaptcta" };
    }

    let lptMatch = part.match(/LEADPROGTABLE/);
    if (lptMatch) {
      return { kind: "leadprogtable" };
    }

    let muralMatch = part.match(/MURAL: (.+)/);
    if (muralMatch) {
      return { kind: "mural", data: muralMatch[1] };
    }

    let quoteMatch = part.match(/QUOTE: (.+) -> (.+)/);
    if (quoteMatch) {
      return { kind: "quote", citation: quoteMatch[1], text: quoteMatch[2] };
    }

    let testimonialMatch = part.match(/TESTIMONIAL/);
    if (testimonialMatch) {
      return { kind: "testimonial", data: testimonials.shift() };
    }

    return {
      kind: "mdown",
      data: postProcess(
        marked
          .parse(part.replace(/\\/g, ""), { headerIds: false, mangle: false })
          .replace(
            /alt="(.*?) BORDERED CAPTIONED"/g,
            'alt="$1" class="bordered captioned"'
          )
          .replace(
            /alt="(.*?) CAPTIONED BORDERED"/g,
            'alt="$1" class="bordered captioned"'
          )
          .replace(/alt="(.*?) CAPTIONED"/g, 'alt="$1" class="captioned"')
          .replace(/alt="(.*?) BORDERED"/g, 'alt="$1" class="bordered"')
          .replace(
            /(<img.+?alt="(.*?)".+?captioned.+?>)/g,
            "<figure> $1 <figcaption>$2</figcaption> </figure>"
          )
          .replace('.pdf"', '.pdf" download')
      ),
    };
  });
</script>

{#each parts as part}
  {#if part.kind == "audio"}
    <div class="audio">
      <AudioPlayer
        backgroundColor="var(--ps-colors-snow)"
        barPrimaryColor="var(--ps-colors-mid-grey)"
        barSecondaryColor="var(--ps-colors-light-grey)"
        display={true}
        iconColor="var(--ps-colors-mid-grey)"
        src={part.data}
        textColor="var(--ps-colors-mid-grey)"
      />
    </div>
  {:else if part.kind == "download"}
    <a class="button mdown {dlButtonClass}" download href={part.data}
      >{dlButtonLabel}</a
    >
  {:else if part.kind == "leadprogadaptcta"}
    <div id="lead-prog-adapt-cta">
      <LeadProgAdaptCta />
    </div>
  {:else if part.kind == "leadprogtable"}
    <div id="lead-prog-table">
      <LeadProgTable />
    </div>
  {:else if part.kind == "mural"}
    <MuralBoard id={part.data} />
  {:else if part.kind == "mdown"}
    <div class="mdown" class:styleStrongWhite {style}>
      {@html part.data}
    </div>
  {:else if part.kind == "quote"}
    <Quote citation={part.citation} hideIcon paras={part.text.split("\n")} />
  {:else if part.kind == "testimonial"}
    <Quote
      citation={part.data.citationOverride || part.data.citation}
      hideIcon
      photo={part.data.image}
      paras={part.data.text.split("\n")}
      photoInline
      photoSmall
    />
  {:else}
    <div>
      <h3>UNKNOWN MARKDOWN KIND</h3>
      <pre>{JSON.stringify(part)}</pre>
    </div>
  {/if}
{/each}

<style>
  .audio {
    margin-bottom: 2rem;
  }

  .mdown {
    text-align: left;
  }

  .mdown :global(a) {
    text-decoration-line: underline;
  }

  .mdown :global(blockquote) {
    border-left: 3px solid var(--ps-colors-light-grey);
    font-style: italic;
    margin-bottom: 1.5rem;
    padding-left: 1.5rem;
  }

  .mdown :global(img.bordered) {
    border: 5px solid var(--ps-colors-gold);
    border-radius: 20px;
  }

  .mdown :global(img + em) {
    display: block;
    font-size: var(--ps-fontsize-tiny);
    margin-top: 5px;
  }

  .mdown :global(li) {
    margin-bottom: 0.5rem;
    margin-left: 1.5rem;
  }

  .mdown :global(li ol:first-of-type),
  .mdown :global(li ul:first-of-type) {
    margin-top: 0.5rem;
  }

  .mdown :global(ol) {
    margin-bottom: 1.5rem;
    text-align: left;
    width: 100%;
  }

  /* TODO: revisit this hack if we end up with multiple examples of OLs in OLs */
  .mdown :global(ol ol) {
    list-style-type: circle;
  }

  .mdown :global(p) {
    margin-bottom: 1.5rem;
  }

  /* TODO: make this less hacky */
  .mdown.styleStrongWhite :global(strong) {
    color: white;
  }

  .mdown :global(ul) {
    list-style-type: circle;
    margin-bottom: 1.5rem;
    text-align: left;
    width: 100%;
  }

  #lead-prog-adapt-cta {
    display: flex;
    justify-content: center;
  }

  #lead-prog-adapt-cta,
  #lead-prog-table {
    margin: 2rem 0;
    width: 100%;
  }
</style>
