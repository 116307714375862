<script>
  import { icon } from "@fortawesome/fontawesome-svg-core";

  export let i;
  export let gold = false;
  export let large = false;
  export let lighter = false;
  export let lightgold = false;
  export let onClick = () => {};
  export let transSize = null;

  let iconProps = {};
  if (transSize) iconProps = { transform: { size: transSize } };
</script>

<div
  class="icon"
  class:gold
  class:is-large={large}
  class:lighter
  class:lightgold
  on:click={onClick}
>
  {@html icon(i, iconProps).html}
</div>

<style>
  .gold {
    color: var(--ps-colors-dark-gold);
  }

  .lighter {
    color: var(--ps-colors-light-grey);
  }

  .lightgold {
    color: var(--ps-colors-light-gold);
  }
</style>
