<script>
  import { faHammer, faHeart } from "@fortawesome/free-solid-svg-icons";
  import { airtableData } from "../airtable/compiled";
  import Container from "../components/Container.svelte";
  import Icon from "../components/Icon.svelte";
  import KeyOfferings from "../components/KeyOfferings.svelte";
  import LogoGrid from "../components/LogoGrid.svelte";
  import NavLink from "../components/NavLink.svelte";
  import Quote from "../components/Quote.svelte";
  import Section from "../components/Section.svelte";
  import WorkshopNav from "../components/WorkshopNav.svelte";
  import { consultingPartners as partners } from "../partnerLogos";
  import { undrawImageAlt } from "../undrawImageAlts";

  const testimonials = airtableData.testimonials.filter(
    (t) => t.pagePaths[0] == "/workshops"
  );
</script>

<Section bgColor="dark-grey">
  <Container>
    <img
      alt={undrawImageAlt("hang_out_h9ud")}
      class="head mb1r"
      height="801"
      src="/img/illustrations/gold/undraw_hang_out_h9ud.svg"
      width="1125"
    />
    <h1>Workshops</h1>
    <p class="strapline">
      <span style="color:hotpink">Engaging</span>, interactive and
      <span style="color:gold">impactful</span> workshops to tackle a myriad of workplace
      challenges
    </p>
    <p class="scores">
      <span style="color:hotpink">
        <Icon i={faHeart} transSize={15} /> 4.7 / 5
      </span>
      <span style="color:gold; margin-left: 20px">
        <Icon i={faHammer} transSize={15} /> 4.8 / 5
      </span>
      <br />
      <span style="color:gray"> (average participant scores) </span>
    </p>
    <NavLink button buttonClass="is-gold is-medium" to="/book-disco-call">
      Book a call
    </NavLink>
  </Container>
</Section>

<Section noPad>
  <Container>
    <WorkshopNav
      filter={(w) => w.status == "Publish" && !w.isFacilitationService}
      groups={Array.from(
        new Set(
          airtableData.workshops
            .filter((w) => w.status == "Publish" && !w.isFacilitationService)
            .map((w) => w.category)
        )
      ).sort((g1, g2) => g1.localeCompare(g2))}
    />
    <hr />
  </Container>

  <Container innerPad>
    <h3>Key Programs</h3>
    <KeyOfferings />
  </Container>
</Section>

<Section bgColor="dark-grey">
  <Container>
    <h2>Our Results</h2>
    <p>We're proud to support teams from a wide range of organizations.</p>
  </Container>
</Section>
<Section>
  <Container>
    <LogoGrid {partners} />
    <div class="columns">
      {#each testimonials as t}
        <div class="column color-bg-mid-grey spaced">
          <Quote
            citation={t.citationOverride || t.citation}
            photo={t.image}
            paras={t.text.split("\n")}
          />
        </div>
      {/each}
    </div>
  </Container>
  <Container innerPad>
    <NavLink button to="/why-us-facilitation" buttonClass="is-dark">
      Read more feedback
    </NavLink>
  </Container>
</Section>

<!-- <Section bgColor="jet">
  <Container>
    <CaseStudies goldButtons />
  </Container>
</Section> -->

<style>
  p.scores :global(.icon) {
    vertical-align: text-top;
  }
</style>
