<script>
  export let id;
  export let style = "";
  export let title;
</script>

<div {style}>
  <iframe
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    allowfullscreen=""
    frameborder="0"
    height="100%"
    src="//www.youtube.com/embed/{id}"
    {title}
    width="100%"
  />
</div>

<style>
  div {
    display: inline-block;
    max-height: 540px;
    max-width: 960px;
    position: relative;
    width: 100%;
  }

  div::after {
    padding-top: 56.25%; /* 16x9 */
    display: block;
    content: "";
  }

  iframe {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
</style>
