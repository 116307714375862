<script>
  import { airtableData } from "../airtable/compiled";
  import NavLink from "../components/NavLink.svelte";
  import { undrawImageAlt } from "../undrawImageAlts";

  export let homePage = false;

  let programs = airtableData.programs
    .filter((p) => (homePage ? p.showOnHomePage : p.showOnWorkshopsPage))
    .map(
      ({ image, koProgramSuffix, koSeq, koText, name, strapline, stub }) => ({
        image,
        koSeq,
        koText,
        name: koProgramSuffix ? `${name} Program` : name,
        url: `/p/${stub}`,
      })
    );

  let workshops = airtableData.workshops
    .filter((w) => (homePage ? w.showOnHomePage : false))
    .map(({ image, koSeq, name, stub }) => ({
      image,
      name: name.replace(" Workshop", ""),
      koSeq,
      url: `/w/${stub}`,
    }));

  const offerings = programs
    .concat(workshops)
    .sort((o1, o2) => o1.koSeq - o2.koSeq);
</script>

<div class="columns">
  {#each offerings as o}
    <div class="column course">
      <img
        alt={undrawImageAlt(o.image)}
        class="mb1r"
        src="/img/illustrations/gold/undraw_{o.image}.svg"
        style="height:150px"
      />
      {#if !homePage && o.koText}
        <p>{o.koText}</p>
      {/if}
      <NavLink button buttonClass="is-gold" to={o.url}>
        {o.name}
      </NavLink>
    </div>
  {/each}
</div>

<style>
  /* TODO: hardcoded breakpoint */
  @media screen and (max-width: 768px) {
    .column.course {
      border-top: 1px dotted var(--ps-colors-dark-grey);
    }

    .column.course:first-child {
      border-top: none;
    }
  }
</style>
