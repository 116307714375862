<script>
  import { routesByPath } from "../routes";
  import NavLink from "./NavLink.svelte";

  export let onClick;
  export let route;
</script>

{#if route.children || route.childrenFlat}
  <div class="navbar-item has-dropdown is-hoverable">
    <div class="navbar-item">
      {#if route.component}
        <NavLink to={route.path} {onClick}>{route.name}</NavLink>
      {:else}{route.name}{/if}
    </div>

    <div class="navbar-dropdown">
      {#each route.children || [] as path}
        <svelte:self {onClick} route={routesByPath[path]} />
      {/each}
      {#each Object.entries(route.childrenFlat || {}) as [path, name]}
        <div class="navbar-item">
          <NavLink to={path} {onClick}>{name}</NavLink>
        </div>
      {/each}
    </div>
  </div>
{:else}
  <div class="navbar-item">
    <NavLink to={route.path} {onClick}>{route.name}</NavLink>
  </div>
{/if}
