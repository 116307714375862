import { airtableData } from "../airtable/compiled";
import { postsByStub } from "./compiled";

export function posts(withExt = false) {
  let posts = [];

  for (const atPost of airtableData.posts) {
    const { stub } = atPost;

    if (atPost.redirUrl) continue;

    if (atPost.extUrl) {
      if (!withExt) continue;

      posts.push({
        date: atPost.date || "",
        hostedBy: atPost.extUrl
          .split("://")[1]
          .split("/")[0]
          .split(".")
          .slice(-2, -1)
          .join("."),
        link: atPost.extUrl,
        summary: atPost.summary,
        stub: atPost.stub,
        thumbAlt: "external post image",
        thumbLink: `/img/blog/external-post-thumbs/${stub}.${atPost.thumbExt}`,
        title: atPost.title,
      });
    }

    const post = postsByStub[stub];
    if (!post) continue;

    let relatedText = atPost.relatedText;
    if (relatedText) {
      relatedText = relatedText.trim();
      if (relatedText.startsWith("N/A")) relatedText = null;
    }

    posts.push({
      audio: post.audio,
      body: post.body,
      date: atPost.date || "",
      link: `/post/${stub}`,
      relatedText,
      stub,
      summary: atPost.summary,
      thumbAlt: post.images[0].alt,
      thumbLink: post.images[0].src,
      title: post.title,
      wordCount: post.wordCount,
    });
  }

  return posts.sort((p1, p2) => p1.date.localeCompare(p2.date)).reverse();
}
