<script>
  import QueryString from "query-string";
  import Container from "../components/Container.svelte";
  import Section from "../components/Section.svelte";
  import YouTubeVideo from "../components/YouTubeVideo.svelte";

  let show = { butter: false, miro: false, mural: true, zoom: true };

  if (typeof window !== "undefined") {
    const tools = QueryString.parse(window.location.search);
    if (Object.keys(tools).length > 0) {
      show = {};
      for (const t in tools) show[t] = true;
    }
  }
</script>

<Section bgColor="dark-grey">
  <Container>
    <h1>Digital Tools Onboarding Guide</h1>
    <p>
      A virtual gathering goes most smoothly when all the participants have some
      confidence with the digital tools they'll be using.
    </p>
    <p>During our time together, you'll need to be comfortable with...</p>
  </Container>
</Section>

{#if show.butter}
  <Section>
    <Container>
      <div class="columns">
        <div class="column is-one-quarter">
          <h2>Butter</h2>
          <h3>Video Conferencing</h3>
          <img class="logo" alt="butter logo" src="/img/services/butter.png" />
        </div>
        <div class="column detail">
          <p>
            At PeopleStorming, we are committed to providing the best experience
            possible which sometimes means branching out and using new tools.
          </p>
          <p>
            After a few years of Zooming, we've decided to transition our
            workshops to Butter. It's a newer tool built by a <strong
              >very</strong
            > customer focused team. It has some awesome features that help us as
            hosts and you as participants.
          </p>
          <p class="tip">
            Butter <strong>does not support</strong> mobiles / tablets so make sure
            you're on a laptop / desktop when you join the call.
          </p>
          <p>
            If you run into any issues, <a
              href="mailto:hello@peoplestorming.com"
              rel="noopener"
              target="_blank">drop us a note</a
            > and we'll be glad to help.
          </p>
          <p>
            Lastly, you probably won't need it but, just in case, Butter have a <a
              href="https://help.butter.us/"
              rel="noopener"
              target="_blank">comprehensive guide</a
            >.
          </p>
        </div>
      </div>
    </Container>
  </Section>
{/if}

{#if show.miro}
  <Section>
    <Container>
      <div class="columns">
        <div class="column is-one-quarter">
          <h2>Miro</h2>
          <h3>Digital Whiteboarding</h3>
          <img class="logo" alt="mural logo" src="/img/services/miro.svg" />
        </div>
        <div class="column detail">
          <p>
            You don't need to know a lot to be able to use Miro in our
            workshops. Please just take a quick few minutes to make sure you
            understand how to...
          </p>
          <ul>
            <li>
              <a
                href="https://help.miro.com/hc/en-us/articles/360017731053-Navigation-Mode/"
                rel="noopener"
                target="_blank"
              >
                navigate using a mouse
              </a>
              (or a trackpad - but we <strong>strongly</strong> recommend using a
              mouse)
            </li>
            <li>
              <a
                href="https://help.miro.com/hc/en-us/articles/360017572054-Sticky-notes"
                rel="noopener"
                target="_blank"
              >
                work with sticky notes
              </a>
              (just the "Getting Started" part of this page)
            </li>
          </ul>
          <p>
            If you're already comfortable with tools like Miro, <a
              href="https://help.miro.com/hc/en-us/articles/360018109574-Work-smarter-not-harder"
              rel="noopener"
              target="_blank"
            >
              here are some pro tips
            </a> that can make you more effective.
          </p>
        </div>
      </div>
    </Container>
  </Section>
{/if}

{#if show.mural}
  <Section>
    <Container>
      <div class="columns">
        <div class="column is-one-quarter">
          <h2>Mural</h2>
          <h3>Digital Whiteboarding</h3>
          <img class="logo" alt="mural logo" src="/img/services/mural.png" />
        </div>
        <div class="column detail">
          <p>Watch these three quick videos to learn how to use Mural...</p>
          <YouTubeVideo
            id="uds8lsv-CEQ"
            style="margin-bottom: 1.5rem"
            title="Navigating The Mural"
          />
          <p class="tip">
            Tip: We recommend that you use a mouse rather than a laptop trackpad
            when accessing Mural in order to avoid the need for finger
            gymnastics. When you do so, <strong
              >make sure Mural is in mouse mode</strong
            > using the Zoom Settings button.
          </p>
          <YouTubeVideo
            id="CmYaxcTzD6Y"
            style="margin-bottom: 1.5rem"
            title="Adding Content In Mural"
          />
          <YouTubeVideo
            id="ltpn4G2aOns"
            style="margin-bottom: 1.5rem"
            title="Formatting Content In Mural"
          />
        </div>
      </div>
    </Container>
  </Section>
{/if}

{#if show.zoom}
  <Section>
    <Container>
      <div class="columns">
        <div class="column is-one-quarter">
          <h2>Zoom</h2>
          <h3>Video Conferencing</h3>
          <img class="logo" alt="zoom logo" src="/img/services/zoom.png" />
        </div>
        <div class="column detail">
          <p>
            We always need people to join using the <strong
              >latest <a
                href="https://zoom.us/download"
                rel="noopener"
                target="_blank">Zoom app</a
              > on their desktops / laptops</strong
            >. You won't be able to participate fully if you join from a tablet,
            mobile phone or web browser (as there will be key features missing).
          </p>
          <p>
            We also ask people to make sure that Zoom is configured with their <strong
              >full name</strong
            > to help with intros and coordination.
          </p>
          <p>You'll need to know how to...</p>
          <ul>
            <li>
              <a
                href="https://support.zoom.us/hc/en-us/articles/201362193-Joining-a-meeting"
                rel="noopener"
                target="_blank">Join a Zoom meeting</a
              >
              <ul>
                <li>
                  Try out a <a
                    href="https://zoom.us/test"
                    rel="noopener"
                    target="_blank">test meeting room</a
                  >, if you like
                </li>
              </ul>
            </li>
            <li>
              Use <a
                href="https://support.zoom.us/hc/en-us/articles/200941109-Attendee-controls-in-a-meeting"
                rel="noopener"
                target="_blank">attendee controls</a
              >...
              <ul>
                <li>Change your display name</li>
                <li>Mute / unmute your microphone</li>
                <li>Turn your camera on / off</li>
                <li>Use the in-Zoom text chat</li>
                <li>Use reactions</li>
                <li>
                  <a
                    href="https://support.zoom.us/hc/en-us/articles/115005706806-Using-annotation-tools-on-a-shared-screen-or-whiteboard"
                    rel="noopener"
                    target="_blank">Annotate a screen-share</a
                  >
                </li>
              </ul>
            </li>
            <li>
              <a
                href="https://support.zoom.us/hc/en-us/articles/201362323-Changing-the-video-layout-Active-Speaker-View-and-Gallery-View-"
                rel="noopener"
                target="_blank">Control your view mode</a
              >
              <ul>
                <li>
                  Maximize the number of people you can see by selecting Gallery
                  View
                </li>
                <li>
                  When we're sharing something, slide the divider between the
                  gallery of participants and what we're sharing so you can see
                  more people
                </li>
                <li>
                  Tick the video setting to Hide Non-video Participants -
                  ensuring that you make best use of your screen space
                </li>
              </ul>
              <img alt="zoom tips" src="/img/zoom-tips.png" />
            </li>
          </ul>
        </div>
      </div>
    </Container>
  </Section>
{/if}

<style>
  .columns {
    border: 1px solid var(--ps-colors-light-grey);
    border-radius: 5px;
  }

  .detail {
    align-items: flex-start;
    text-align: left;
  }

  .detail a {
    text-decoration-line: underline;
  }

  .detail li {
    margin-bottom: 0.5rem;
    margin-left: 1.5rem;
  }

  .detail li ul:first-of-type {
    margin-top: 0.5rem;
  }

  .detail ul {
    list-style-type: circle;
    margin-bottom: 1.5rem;
  }

  img.screenshot {
    border: 5px solid var(--ps-colors-light-grey);
    margin-bottom: 1.5rem;
  }

  img.screenshot:hover {
    border-color: var(--ps-colors-gold);
  }

  .logo {
    max-width: 200px;
    width: 100%;
  }

  p.tip {
    font-style: italic;
  }
</style>
