<script>
  import {
    faMap,
    faRocket,
    faRunning,
  } from "@fortawesome/free-solid-svg-icons";

  import Container from "../components/Container.svelte";
  import Icon from "../components/Icon.svelte";
  import LogoGrid from "../components/LogoGrid.svelte";
  import NavLink from "../components/NavLink.svelte";
  import Quote from "../components/Quote.svelte";
  import Section from "../components/Section.svelte";

  import { airtableData } from "../airtable/compiled";
  import { consultingPartners as partners } from "../partnerLogos";
  import { undrawImageAlt } from "../undrawImageAlts";

  const testimonials = airtableData.testimonials.filter(
    (t) => t.pagePaths[0] == "/consulting"
  );
</script>

<Section bgColor="dark-grey">
  <Container>
    <img
      alt={undrawImageAlt("quitting_time_dm8t")}
      class="head mb1r"
      height="766"
      src="/img/illustrations/gold/undraw_quitting_time_dm8t.svg"
      width="1118"
    />
    <h1>Organizational Consulting</h1>
    <p class="strapline">
      Fresh thinking to help you build and sustain a thriving organization
    </p>
    <NavLink button buttonClass="is-gold is-medium" to="/book-disco-call">
      Book a call
    </NavLink>
  </Container>
</Section>

<Section>
  <Container>
    <div class="columns">
      <div class="column">
        <img
          alt={undrawImageAlt("true_love_cy8x")}
          class="mb1r"
          src="/img/illustrations/gold/undraw_true_love_cy8x.svg"
          style="height:150px"
        />
        <h3>Vision & Mission</h3>
        <p>
          We'll help you design a purpose driven organizational vision and
          mission that inspires and motivates your people.
        </p>
      </div>
      <div class="column">
        <img
          alt={undrawImageAlt("abstract_x68e")}
          class="mb1r"
          src="/img/illustrations/gold/undraw_abstract_x68e.svg"
          style="height:150px"
        />
        <h3>Learning & Development</h3>
        <p>
          We'll help you design a learning program that fits with who you are
          now and who you want to become as an organization.
        </p>
      </div>
      <div class="column">
        <img
          alt={undrawImageAlt("process_e90d")}
          class="mb1r"
          src="/img/illustrations/gold/undraw_process_e90d.svg"
          style="height:150px"
        />
        <h3>Organizational Design</h3>
        <p>
          We'll help you explore the assumptions, beliefs, principles,
          practices, processes, and policies that act as the foundation for your
          organizations day-to-day work.
        </p>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <img
          alt={undrawImageAlt("experience_design_eq3j")}
          class="mb1r"
          src="/img/illustrations/gold/undraw_experience_design_eq3j.svg"
          style="height:150px"
        />
        <h3>People Experience</h3>
        <p>
          We'll help you ensure that the end-to-end experience of working at
          your company is engaging and memorable.
        </p>
      </div>
      <div class="column">
        <img
          alt={undrawImageAlt("ask_me_anything_k8o0")}
          class="mb1r"
          src="/img/illustrations/gold/undraw_ask_me_anything_k8o0.svg"
          style="height:150px"
        />
        <h3>Internal Communication</h3>
        <p>
          We'll help you design processes, practices and materials that turn
          your internal communication work from boring to brilliant.
        </p>
      </div>
      <div class="column">
        <img
          alt={undrawImageAlt("hire_te5y")}
          class="mb1r"
          src="/img/illustrations/gold/undraw_hire_te5y.svg"
          style="height:150px"
        />
        <h3>Recruiting</h3>
        <p>
          We'll help you design a recruiting process and candidate experience
          that is inclusive and brings the talent you need to the table.
        </p>
      </div>
    </div>
  </Container>
</Section>

<Section bgColor="dark-grey">
  <Container>
    <h2>Our Unique Blend</h2>
    <p>Organizations choose us because we combine the best thinking from...</p>
  </Container>
</Section>
<Section>
  <Container>
    <div class="columns mb1r">
      <div class="column">
        <Icon i={faMap} gold large transSize={20} />
        <h3>Experience & Service Design</h3>
        <p>
          We'll use powerful tools like Experience Mapping and Blueprinting to
          identify, capture, understand and communicate the interactions that
          make up your peoples' experiences.
        </p>
      </div>
      <div class="column">
        <Icon i={faRunning} gold large transSize={20} />
        <h3>Agile Product Management</h3>
        <p>
          We'll help you shift your thinking from operations and projects to a
          more agile mindset. You'll deliver regular, iterative value as part of
          a portfoio of products and services.
        </p>
      </div>
      <div class="column">
        <Icon i={faRocket} gold large transSize={20} />
        <h3>Facilitation & Coaching</h3>
        <p>
          We'll use a raft of facilitation and coaching techniques to partner
          with you and your team. Together, we'll co-create solutions that you
          understand, are bought-into and can use in the future without relying
          on us.
        </p>
      </div>
    </div>
  </Container>
</Section>

<Section bgColor="gold">
  <Container>
    <p>
      Spill the tea on your workplace woes. We're all ears (and we have
      solutions).
    </p>
    <NavLink button buttonClass="is-dark is-medium" to="/book-disco-call">
      Book a call
    </NavLink>
  </Container>
</Section>

<Section bgColor="dark-grey">
  <Container>
    <h2>Our Track Record</h2>
    <p>We've helped clients from scrappy startups to industry giants.</p>
  </Container>
</Section>

<Section>
  <Container>
    <LogoGrid {partners} />
    <div class="columns">
      {#each testimonials as t}
        <div class="column color-bg-mid-grey spaced">
          <Quote
            citation={t.citationOverride || t.citation}
            photo={t.image}
            paras={t.text.split("\n")}
          />
        </div>
      {/each}
    </div>
  </Container>
  <Container innerPad>
    <NavLink button to="/why-us-facilitation" buttonClass="is-dark">
      Read more feedback
    </NavLink>
  </Container>
</Section>

<Section bgColor="jet">
  <Container>
    <Quote
      citation="Ram Dass"
      large={true}
      paras={["Make friends with change."]}
    />
  </Container>
</Section>

<style>
  /* h3.float-bottom {
    margin-top: auto;
  }

  hr {
    background: none;
    border: 1px dashed var(--ps-colors-dark-grey);
    margin: 2rem auto;
    width: 20rem;
  } */
</style>
