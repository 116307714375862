<script>
  import { onMount } from "svelte";
  import { airtableData } from "../airtable/compiled";
  import Carousel from "../components/Carousel.svelte";
  import Container from "../components/Container.svelte";
  import ImageCarouselCard from "../components/ImageCarouselCard.svelte";
  import LogoGrid from "../components/LogoGrid.svelte";
  import NavLink from "../components/NavLink.svelte";
  import Section from "../components/Section.svelte";
  import TestimonialNav from "../components/TestimonialNav.svelte";
  import { galleriesByName } from "../galleries/compiled";
  import { allPartners as partners } from "../partnerLogos";

  export let route;

  let testimonials = airtableData.testimonials.filter((t) =>
    t.pagePaths[0].includes("/why-us")
  );

  onMount(() => {
    let seqs = [];

    for (let t of testimonials.toSorted(() => Math.random() - 0.5)) {
      let s = seqs[t.seq];
      if (s) s.push(t);
      else seqs[t.seq] = [t];
    }

    testimonials = seqs.flat();
  });

  let gallery = galleriesByName["why-us"];
</script>

<Section bgColor="dark-grey">
  <Container>
    <h1>Why Us?</h1>
    <p class="strapline">Our clients love us...</p>
  </Container>
</Section>

<Section>
  <Container>
    <TestimonialNav
      initialCount={9}
      items={testimonials}
      subset={route.testimonials}
    />
    <NavLink button buttonClass="is-gold" to="/book-call">Book a call</NavLink>
  </Container>
</Section>

<Section>
  <Container>
    <LogoGrid {partners} />
  </Container>
</Section>

<!-- <Section bgColor="dark-grey">
  <Container>
    <CaseStudies goldButtons />
  </Container>
</Section> -->

<Section bgColor="light-grey" squarePad>
  <Container>
    <Carousel
      auto
      component={ImageCarouselCard}
      items={gallery}
      style="height:348px"
    />
  </Container>
</Section>
