<script>
  import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
  import Container from "../components/Container.svelte";
  import Icon from "../components/Icon.svelte";
  import NavLink from "../components/NavLink.svelte";
  import Section from "../components/Section.svelte";

  const featureGroups = [
    {
      name: "Scheduling",
      items: [
        ["Self-service scheduling", true, true],
        // ["Reschedule or cancel same-day without penalty", false, true],
        ["Reserve a regular day/time slot", false, true],
      ],
    },
    {
      name: "Payment",
      items: [
        ["Able to pay by card", true, true],
        ["Able to pay by bank transfer", false, true],
        ["Invoicing available", false, true],
      ],
    },
    // {
    //   name: "Asynchronous Services",
    //   items: [
    //     ["Unlimited email coaching", false, true],
    //     ["Unlimited text coaching", false, true],
    //     ["Offline document / resume review available", false, true],
    //   ],
    // },
  ];
</script>

<Section bgColor="dark-grey">
  <Container>
    <h1>Coaching Options</h1>
    <p class="strapline">
      After your free introduction call, you have a couple of choices...
    </p>
  </Container>
</Section>

<table>
  <thead>
    <tr>
      <th />
      <th class="option1">Single</th>
      <th class="option2">Membership</th>
    </tr>
    <tr>
      <td />
      <td class="option1">$375 &middot; £320</td>
      <td class="option2">$2150 &middot; £1850</td>
    </tr>
  </thead>
  <tbody>
    <tr class="group">
      <td colspan="3">Coaching</td>
    </tr>
    <tr>
      <td class="feature-name"> Video calls </td>
      <td class="feature-flag"> 1 </td>
      <td class="feature-flag">
        6 <p class="detail">over 12 weeks</p>
      </td>
    </tr>
    {#each featureGroups as fg}
      <tr class="group">
        <td colspan="3">{fg.name}</td>
      </tr>
      {#each fg.items as f}
        <tr>
          <td class="feature-name"> {f[0]} </td>
          <td class="feature-flag">
            <Icon i={f[1] ? faCheck : faTimes} lighter={!f[1]} />
          </td>
          <td class="feature-flag">
            <Icon i={f[2] ? faCheck : faTimes} lighter={!f[2]} />
          </td>
        </tr>
      {/each}
    {/each}
    <tr class="cta">
      <td />
      <td>
        <NavLink button buttonClass="is-gold" to="/book-call">
          Book call
        </NavLink>
      </td>
      <td>
        <NavLink button buttonClass="is-gold" to="/buy-coaching-plan">
          Buy membership
        </NavLink>
      </td>
    </tr>
  </tbody>
</table>

<style>
  table {
    margin: 2rem auto;
  }

  td,
  th {
    border-right: 1px solid var(--ps-colors-dark-grey);
    padding: 10px;
  }

  td.feature-flag {
    text-align: center;
  }

  td.feature-name {
    max-width: 300px;
  }

  td.option1,
  th.option1 {
    background-color: var(--ps-colors-mid-grey);
  }

  td.option2,
  th.option2 {
    background-color: var(--ps-colors-dark-grey);
  }

  thead {
    color: white;
    text-align: center;
  }

  thead th {
    border-top: 1px solid var(--ps-colors-dark-grey);
    color: white;
    font-weight: normal;
    font-size: var(--ps-fontsize-medium);
    padding: 10px 20px 0 20px;
  }

  thead th:empty {
    border-top: none;
  }

  tr.cta td {
    border-bottom: 1px solid var(--ps-colors-dark-grey);
    text-align: center;
  }

  tr.cta td:empty {
    border-bottom: none;
  }

  tr.group {
    background-color: var(--ps-colors-gold);
  }

  p {
    font-style: italic;
    text-align: center;
  }

  p.detail {
    font-size: var(--ps-fontsize-tiny);
  }
</style>
