<script>
  import Container from "../components/Container.svelte";
  import NavLink from "../components/NavLink.svelte";
  import Section from "../components/Section.svelte";

  export let page;

  const infosByPage = {
    book: {
      name: "Book Call",
      path: "/book-call",
    },
    buy: {
      name: "Buy Membership",
      path: "/buy-coaching-plan",
    },
  };

  const pages = ["book", "buy"];
</script>

<Section bgColor="gold" squarePad>
  <Container>
    <div class="tabs is-boxed is-centered is-toggle">
      <ul>
        {#each pages as p}
          <li class:is-active={p == page}>
            <NavLink to={infosByPage[p].path}>{infosByPage[p].name}</NavLink>
          </li>
        {/each}
      </ul>
    </div>
  </Container>
</Section>

<style>
  .tabs {
    overflow: hidden;
  }

  .tabs :global(a) {
    border-color: var(--ps-colors-dark-grey);
    border-radius: 0;
    font-size: var(--ps-fontsize-tiny);
  }

  .tabs :global(a:hover) {
    background-color: initial;
    border-color: var(--ps-colors-dark-grey);
    text-decoration-color: var(--ps-colors-mid-grey);
  }

  .tabs :global(.is-active a:hover) {
    background-color: var(--ps-colors-dark-grey);
    text-decoration-color: var(--ps-colors-gold);
  }

  /* TODO: hardcoded breakpoint */
  @media screen and (max-width: 380px) {
    .tabs :global(a) {
      padding: 5px;
    }
  }

  /* TODO: hardcoded breakpoint */
  @media screen and (min-width: 768px) {
    .tabs :global(a) {
      font-size: var(--ps-fontsize-small);
    }
  }
</style>
