// ensure svelte-routing nav() events reset the window to the top of the screen
history.pushState = new Proxy(history.pushState, {
	apply(target, thisArg, argumentsList) {
		Reflect.apply(target, thisArg, argumentsList);
		scrollTo(0, 0);
	}
});

import App from "./App.svelte";

new App({
	hydrate: true,
	target: document.body,
});
