<script>
  import { airtableData } from "../airtable/compiled";
  import { postsByStub } from "../blog/compiled";

  export let id;
  export let route;

  let description, image, title;

  $: path = route.path.replace(":id", id);
  $: canonicalUrl = `https://www.peoplestorming.com${path}`;

  $: {
    description = route.description;
    image = "/img/logo-banner.png";
    title = route.title;

    if (path.startsWith("/case/")) {
      const study = airtableData.caseStudies.find(
        (cs) => `/case/${cs.stub}` == path
      );
      description = study.context;
      title = `${study.title} Case Study | PeopleStorming`;
    } else if (path.startsWith("/p/")) {
      const info = airtableData.programs.find((p) => `/p/${p.stub}` == path);
      description = info.strapline;
      title = `${info.name} Program | PeopleStorming`;
    } else if (path.startsWith("/post/")) {
      const info = airtableData.posts.find((p) => `/post/${p.stub}` == path);
      const post = postsByStub[info.stub];
      description = info.summary;
      image = post.images[0].src || image;
      title = `${post.title} | PeopleStorming`;
    } else if (path.startsWith("/u/")) {
      const info = airtableData.upgrades.find((u) => `/u/${u.stub}` == path);
      description = info.summary;
      title = `Upgrade Your Team's ${
        info.stub[0].toUpperCase() + info.stub.slice(1)
      } | PeopleStorming`;
    } else if (path.startsWith("/w/")) {
      const info = airtableData.workshops.find((w) => `/w/${w.stub}` == path);
      description = info.summary;
      title = `${info.name} | PeopleStorming`;
    }

    description = description.replace(/\n/g, " ");
  }
</script>

<svelte:head>
  {#if path}
    <!-- Disable Indexing -->
    {#if route.stealth}
      <meta name="robots" content="noindex, nofollow" />
    {/if}

    <!-- Baseline -->
    <link rel="canonical" href={canonicalUrl} />
    <meta name="description" content={description} />
    <title>{title}</title>

    <!-- Open Graph -->
    <meta property="og:description" content={description} />
    <meta property="og:image" content={image} />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={canonicalUrl} />
    <meta property="og:title" content={title} />

    <!-- Twitter -->
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:creator" content="@PeopleStorming" />
    <meta
      name="twitter:image"
      content="https://www.peoplestorming.com{image}"
    />
    <meta name="twitter:site" content="@PeopleStorming" />
  {/if}
</svelte:head>
