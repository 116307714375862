<script>
  import { Link } from "svelte-routing";

  export let button = false;
  export let buttonClass = "";
  export let onClick;
  export let to = "";
  export let style = "";

  function getProps({ location, href, isPartiallyCurrent, isCurrent }) {
    const props = { style };

    if (button) props.class = `button ${buttonClass}`;

    if (isCurrent) props.style += ";text-decoration: underline;";

    return props;
  }

  function _onClick() {
    if (onClick) onClick();
  }
</script>

<Link {to} {getProps} on:click={_onClick}>
  <slot />
</Link>
