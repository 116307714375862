<script>
  export let bgColor;
  let bgColorClass = bgColor ? `color-bg-${bgColor}` : "";

  export let innerPad;
  export let vertPad;
</script>

<div class="container {bgColorClass}" class:innerPad class:vertPad>
  <slot />
</div>

<style>
  div.innerPad {
    padding: 1.5rem;
  }

  div.vertPad {
    padding: 1.5rem 0;
  }
</style>
