<script>
  export let active = false;
  function deactivate() {
    active = false;
  }

  export let spinner = false;
</script>

<svelte:window
  on:keydown={(evt) => {
    if (evt.keyCode == 27) active = false;
  }}
/>

<!-- TODO: prevent scroll / mousewheel events leaking through to underlying page -->
<div class="modal" class:is-active={active}>
  <div class="modal-background" on:click={deactivate} />
  <div class="modal-card">
    <header class="modal-card-head">
      <button class="delete" on:click={deactivate} />
    </header>
    <section class="noContainer modal-card-body" class:spinner>
      <slot />
    </section>
  </div>
</div>

<style>
  .modal-card-body {
    color: white;
  }

  .modal-card-body :global(label) {
    color: white;
  }

  .modal-card-head button {
    position: absolute;
  }

  .spinner {
    background-image: url(/img/icon-spinning.gif);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 200px;
  }
</style>
