import { getContext } from "svelte";
import { ROUTER } from "svelte-routing/src/contexts";

export function getActiveRoute() {
  return getContext(ROUTER).activeRoute;
}

export function slices(array, size) {
  const slices = [];

  for (let i = 0; i < array.length; i += size)
    slices.push(array.slice(i, size + i));

  return slices;
}
