<script>
  import {
    faBullseye,
    faLightbulb,
    faMap,
    faMeh,
    faMountain,
    faPencilRuler,
    faPeopleCarry,
    faPuzzlePiece,
    faSeedling,
    faStar,
    faStreetView,
    faTree,
    faUsers,
  } from "@fortawesome/free-solid-svg-icons";
  import { airtableData } from "../airtable/compiled";
  import Container from "../components/Container.svelte";
  import Icon from "../components/Icon.svelte";
  import LogoGrid from "../components/LogoGrid.svelte";
  import NavLink from "../components/NavLink.svelte";
  import Quote from "../components/Quote.svelte";
  import Section from "../components/Section.svelte";
  import { coachingPartners as partners } from "../partnerLogos";
  import { undrawImageAlt } from "../undrawImageAlts";

  export let route;
  const stub = route.path.replace("/", "");

  const page = airtableData.coachingPages.find((cp) => cp.stub == stub);
  if (!page) throw `no coaching page found for ${stub}`;

  const blurbs = [
    [page.blurb1, page.blurb1Image],
    [page.blurb2, page.blurb2Image],
    [page.blurb3, page.blurb3Image],
  ];

  const facetNamesBySubTypeByType = {};
  for (const f of airtableData.coachingPageFacets) {
    if (!f.stubs.includes(stub)) continue;

    let facetNamesBySubType = facetNamesBySubTypeByType[f.type];
    if (!facetNamesBySubType)
      facetNamesBySubType = facetNamesBySubTypeByType[f.type] = {};

    let facetNames = facetNamesBySubType[f.subType];
    if (!facetNames) facetNames = facetNamesBySubType[f.subType] = [];

    facetNames.push(f.name);
  }
  const challenges = facetNamesBySubTypeByType.Challenge;
  const tools = facetNamesBySubTypeByType.Tool;

  const iconsByFacetName = {
    // Challenges
    "Personal Challenges": faMeh,
    "Role Challenges": faPuzzlePiece,
    "Team Challenges": faUsers,
    // Tools
    "Career Tools": faMap,
    "Craft Tools": faPencilRuler,
    "Self-Improvement": faSeedling,
    "Leadership Growth": faTree,
  };
  function facetIcon(facetName) {
    return iconsByFacetName[facetName] || faStar;
  }

  const subPages = airtableData.coachingPages.filter(
    (cp) => cp.stub != "coaching"
  );

  const testimonials = airtableData.testimonials.filter(
    (t) => t.pagePaths[0] == `/${stub}`
  );

  const title = stub == "coaching" ? "Coaching" : route.name;
</script>

<Section bgColor="dark-grey">
  <Container>
    <img
      alt={undrawImageAlt(page.image)}
      class="head mb1r"
      height="250"
      src="/img/illustrations/gold/undraw_{page.image}.svg"
      width="250"
    />
    <h1>{title}</h1>
    <p class="strapline">
      {page.strapline}
    </p>
    <NavLink button buttonClass="is-gold is-medium" to="/book-call">
      Book a call
    </NavLink>
  </Container>
</Section>

<Section>
  <Container>
    <div class="columns">
      {#each blurbs as [text, image]}
        <div class="column">
          <img
            alt={undrawImageAlt(image)}
            class="mb1r"
            src="/img/illustrations/gold/undraw_{image}.svg"
            style="height:150px"
          />
          <p>
            {text}
          </p>
        </div>
      {/each}
    </div>
  </Container>
</Section>

{#if stub == "coaching"}
  <Section bgColor="dark-grey">
    <Container>
      <h2>Who We Serve</h2>
      <p>Our coachees come from all industries and roles.</p>
      <p>
        With our combined experiences and backgrounds, we are particularly
        effective at supporting people in tech based organizations.
      </p>
    </Container>
  </Section>
  <Section>
    <Container>
      <div class="columns is-multiline">
        {#each subPages as sp}
          <div class="column">
            <img
              alt={undrawImageAlt(sp.image)}
              class="mb1r"
              src="/img/illustrations/gold/undraw_{sp.image}.svg"
              style="height:150px"
            />
            <h3>{sp.whoServed}</h3>
          </div>
        {/each}
      </div>
    </Container>
  </Section>
{/if}

<Section bgColor="dark-grey">
  <Container>
    <h2>Our Approach</h2>
    <p>
      Good coaching relies on getting the fundamentals right. We never lose
      sight of the magic five.
    </p>
  </Container>
</Section>
<Section>
  <Container>
    <div class="columns is-multiline">
      <div class="column">
        <Icon i={faBullseye} gold large transSize={20} />
        <h3>Focus</h3>
        <p>Define and focus on your desired goals</p>
      </div>
      <div class="column">
        <Icon i={faStreetView} gold large transSize={20} />
        <h3>Analyze</h3>
        <p>Explore your situation using more beautiful questions</p>
      </div>
      <div class="column">
        <Icon i={faLightbulb} gold large transSize={20} />
        <h3>Ideate</h3>
        <p>Brainstorm your potential courses of action</p>
      </div>
      <div class="column">
        <Icon i={faMountain} gold large transSize={20} />
        <h3>Plan</h3>
        <p>Decide on your course and develop an action plan</p>
      </div>
      <div class="column">
        <Icon i={faPeopleCarry} gold large transSize={20} />
        <h3>Deliver</h3>
        <p>Execute your plan, stay accountable, reflect on learning</p>
      </div>
    </div>
  </Container>
</Section>

<Section bgColor="dark-grey">
  <Container>
    <h2>Our Results</h2>
    <p>
      Our clients build successful careers at a diverse range of organizations.
    </p>
    <NavLink button buttonClass="is-gold" to="/book-call">Book a call</NavLink>
  </Container>
</Section>
<Section>
  <Container>
    <LogoGrid {partners} />
    <div class="columns">
      {#each testimonials as t}
        <div class="column color-bg-mid-grey spaced">
          <Quote
            citation={t.citationOverride || t.citation}
            photo={t.image}
            paras={t.text.split("\n")}
          />
        </div>
      {/each}
    </div>
  </Container>
  <Container innerPad>
    <NavLink
      button
      to={page.useCTRs ? "/coaching-tech-refs" : "/why-us-coaching"}
      buttonClass="is-dark"
    >
      Read more feedback
    </NavLink>
  </Container>
</Section>

<Section bgColor="jet">
  <Container>
    <Quote citation={page.quoteCitation} large paras={page.quote.split("\n")} />
  </Container>
</Section>

<Section bgColor="dark-grey">
  <Container>
    <h2>Your Challenges</h2>
    <p>We coach clients through a broad range of challenges.</p>
  </Container>
</Section>
<Section>
  <Container>
    <div class="columns is-multiline">
      {#each Object.keys(challenges) as area}
        <div class="column">
          <Icon i={facetIcon(area)} gold large transSize={20} />
          <h3>{area}</h3>
          <ul>
            {#each challenges[area] as challenge}
              <li>{challenge}</li>
            {/each}
          </ul>
        </div>
      {/each}
    </div>
  </Container>
</Section>

<!-- TODO: de-dupe with challenges code block -->
<Section bgColor="dark-grey">
  <Container>
    <h2>Our Tools And Methods</h2>
    <p>
      We combine research-backed, proven and effective tools, models and methods
      to support you in almost any work-related circumstance.
    </p>
  </Container>
</Section>
<Section>
  <Container>
    <div class="columns is-multiline">
      {#each Object.keys(tools) as area}
        <div class="column">
          <Icon i={facetIcon(area)} gold large transSize={20} />
          <h3>{area}</h3>
          <ul>
            {#each tools[area] as tool}
              <li>{tool}</li>
            {/each}
          </ul>
        </div>
      {/each}
    </div>
  </Container>
</Section>

<Section bgColor="jet">
  <Container>
    <div class="columns">
      <div class="column profile">
        <img
          alt="andre"
          class="adaptive-200 rounded"
          height="200"
          src="/img/team/andre.jpg"
          width="200"
        />
        <h3>
          Coach Spotlight: <span class="name">Andre</span>
        </h3>
        <NavLink button buttonClass="is-gold" to="/book-call"
          >Book a call with Andre</NavLink
        >
      </div>
      <div class="column">
        {#each page.andreProfile.split("\n") as p}
          <p>{p}</p>
        {/each}
      </div>
    </div>
  </Container>
</Section>

<style>
  h3 {
    white-space: nowrap;
  }

  li {
    padding-bottom: 0.75rem;
  }

  .profile :global(a) {
    margin: 0;
  }

  .profile h3 {
    margin-top: 1rem;
  }

  .profile img {
    border: 3px solid var(--ps-colors-gold);
  }
</style>
