<script>
  import { airtableData } from "../airtable/compiled";
  import Container from "../components/Container.svelte";
  import KeyOfferings from "../components/KeyOfferings.svelte";
  import LogoGrid from "../components/LogoGrid.svelte";
  import NavLink from "../components/NavLink.svelte";
  import Quote from "../components/Quote.svelte";
  import Section from "../components/Section.svelte";
  import TextRoller from "../components/TextRoller.svelte";
  import { allPartners as partners } from "../partnerLogos";
  import { undrawImageAlt } from "../undrawImageAlts";

  const coachings = airtableData.coachingPages
    .filter((c) => c.homepage)
    .sort((c1, c2) => c2.whoServed.localeCompare(c1.whoServed));

  const offerings = airtableData.programs
    .concat(airtableData.workshops)
    .filter((o) => o.showOnHomePage)
    .sort((o1, o2) => o1.name.localeCompare(o2.name));

  const testimonials = airtableData.testimonials.filter(
    (t) => t.pagePaths[0] == "/"
  );

  const defaultUpgradeStub = "effectiveness";
  const upgradeStubs = airtableData.upgrades
    .map((u) => u.stub)
    .filter((s) => s != defaultUpgradeStub);
  upgradeStubs.unshift(defaultUpgradeStub);
</script>

<div id="hero">
  <Section bgColor="light-gold">
    <Container>
      <h1>
        Upgrade your team's <TextRoller items={upgradeStubs} />
      </h1>
      <p class="main">
        with live, interactive, bitesize, virtual workshops<br />
        hosted by experienced leaders
      </p>
      <NavLink button buttonClass="is-dark is-medium" to="/book-call">
        Book a call
      </NavLink>
    </Container>
  </Section>
</div>

<Section noContainer noPad>
  <div class="columns tri-banner">
    <div class="column color-bg-mid-grey">
      <h3>We help you uncover your real challenges</h3>
    </div>
    <div class="column color-bg-dark-grey">
      <h3>We facilitate your team's effective collaboration</h3>
    </div>
    <div class="column color-bg-jet">
      <h3>We equip you with tools to increase your impact</h3>
    </div>
  </div>
</Section>

<Section sidePad>
  <Container vertPad>
    <h2>Key Offerings</h2>
    <KeyOfferings homePage />
  </Container>
</Section>

<Section bgColor="mid-grey" sidePad>
  <Container vertPad>
    <h2>Targeted Coaching</h2>
    <div class="columns">
      {#each coachings as c}
        <div class="column">
          <img
            alt={undrawImageAlt(c.image)}
            class="mb1r"
            src="/img/illustrations/gold/undraw_{c.image}.svg"
            style="height:150px"
          />
          <NavLink button buttonClass="is-gold" to="/{c.stub}">
            {c.whoServed}
          </NavLink>
        </div>
      {/each}
    </div>
  </Container>
</Section>

<Section bgColor="jet">
  <Container>
    <p>
      Most virtual trainings and workshops don't
      <strong>spark joy.</strong>
      <br />
      So we 'Marie-Kondo'd them with great results...
    </p>
    <Quote
      citation="Brady - Head Of Talent - Kinship"
      large
      paras={["PeopleStorming are experts in virtual workshops."]}
    />
  </Container>
</Section>

<Section>
  <Container>
    <LogoGrid {partners} />
    <div class="columns">
      {#each testimonials as t}
        <div class="column color-bg-mid-grey spaced">
          <Quote
            citation={t.citationOverride || t.citation}
            photo={t.image}
            paras={t.text.split("\n")}
          />
        </div>
      {/each}
    </div>
  </Container>
  <Container innerPad>
    <NavLink button to="/why-us-facilitation" buttonClass="is-dark">
      Read more feedback
    </NavLink>
  </Container>
</Section>

<style>
  #hero h1 {
    font-weight: bold;
  }

  #hero p.main {
    font-size: var(--ps-fontsize-medium);
  }

  .columns.tri-banner {
    margin: 0;
  }

  .columns.tri-banner h3 {
    margin: 30px 20px;
    max-width: 200px;
  }

  h2 {
    font-size: var(--ps-fontsize-medium);
  }

  h3 {
    font-size: var(--ps-fontsize-small);
    margin: 0 !important;
  }

  /* TODO: hardcoded breakpoint */
  @media screen and (max-width: 768px) {
    .columns.tri-banner h3 {
      max-width: inherit;
    }
  }
</style>
