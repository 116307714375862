<script>
  import { airtableData } from "../airtable/compiled";
  import Carousel from "../components/Carousel.svelte";
  import Container from "../components/Container.svelte";
  import ImageCarouselCard from "../components/ImageCarouselCard.svelte";
  import Markdown from "../components/Markdown.svelte";
  import Section from "../components/Section.svelte";
  import { undrawImageAlt } from "../undrawImageAlts";

  export let id;

  const sections = [
    { key: "challenge", title: "The Challenge", image: "exploring_re_grb8" },
    {
      key: "proposal",
      title: "The Proposal",
      image: "sharing_articles_re_jnkp",
    },
    { key: "sessionOverview", title: "The Overview", image: "teamwork_hpdk" },
    {
      key: "sessionDetail",
      title: "The Details",
      image: "team_spirit_re_yl1v",
    },
    { key: "outcomes", title: "The Outcomes", image: "winners_re_wr1l" },
    { key: "feedback", title: "The Feedback", image: "reviews_lp8w" },
  ];

  $: study = airtableData.caseStudies.find((c) => c.stub == id);

  $: nonEmptySections = sections
    .map((sec) => ((study[sec.key] || "").trim().length > 0 ? sec : null))
    .filter((sec) => sec);

  $: gallery = study.mediaGallery.map((name) => ({
    alt: name.split(".")[0].replace("-", " "),
    src: `/img/cs/${study.stub}/${name}`,
  }));

  $: logoName = study.mediaLogo[0];
  $: logoSrc = logoName ? `/img/cs/${study.stub}/${logoName}` : undefined;
</script>

<Section bgColor="dark-grey">
  <Container>
    {#if logoSrc}
      <img class="logo" src={logoSrc} alt="logo" />
    {/if}
    <h1>{study.title}</h1>
    <div class="columns is-centered mx-0 my-0">
      <div class="column is-two-thirds px-0 py-0">
        <p class="strapline">
          {study.context}
        </p>
      </div>
    </div>
  </Container>
</Section>

{#each nonEmptySections as section, i}
  <Section bgColor={i % 2 == 1 ? "mid-grey" : undefined}>
    <Container>
      <header>
        <img
          src="/img/illustrations/gold/undraw_{section.image}.svg"
          alt={undrawImageAlt(section.image)}
        />
        <h2>{section.title}</h2>
      </header>

      <div class="columns is-centered">
        <div class="column is-two-thirds has-text-left study-text">
          <Markdown content={study[section.key]} />
        </div>
      </div>
    </Container>
  </Section>
{/each}

{#if gallery.length > 0}
  <Section bgColor="light-grey" squarePad>
    <Container>
      <Carousel
        auto
        component={ImageCarouselCard}
        items={gallery}
        style="height:348px"
      />
    </Container>
  </Section>
{/if}

<style>
  header {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    width: 100%;
  }

  header h2 {
    text-align: left;
    width: min-content;
  }

  header img {
    height: 150px;
  }

  img.logo {
    max-height: 100px;
  }

  p {
    width: 100%;
  }

  .study-text :global(figcaption) {
    font-style: italic;
    text-align: center;
  }

  .study-text :global(.quote) {
    background-color: var(--ps-colors-platinum);
    border: 2px solid var(--ps-colors-gold);
    border-radius: 20px;
    color: var(--ps-colors-jet);
    margin-bottom: 1.5rem;
    padding: 1rem 2rem;
  }
</style>
