<script>
  import NavLink from "../components/NavLink.svelte";

  export let link;
  export let style;
</script>

{#if link.startsWith('/')}
  <NavLink {style} to={link}>
    <slot />
  </NavLink>
{:else}
  <a href={link} rel="noopener" {style} target="_blank">
    <slot />
  </a>
{/if}
