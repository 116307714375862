<script>
  import { onMount } from "svelte";
  import BookBuyNav from "../components/BookBuyNav.svelte";

  export let route;

  $: title = route.page == "buy" ? "Buy Coaching Plan" : "Book Call";

  let srcs = {
    book_acuity: "https://app.acuityscheduling.com/schedule.php?owner=20463414",
    book_zm_dev: "http://localhost:8080/book/h9yT4dcwK0n3IAfRZyQQL?sthb=true",
    book_zm_prod: "https://zapmeet.app/book/h9yT4dcwK0n3IAfRZyQQL?sthb=true",
    book_disco_zm_prod:
      "https://zapmeet.app/book/n25Xh8NVgfR2fm1IjzHrI?sthb=true",
    buy_acuity: "https://app.acuityscheduling.com/catalog.php?owner=20463414",
    buy_zm_dev: "https://localhost:8080/book/N8s_OGaZrHu_rrBvYDjcP?sthb=true",
    buy_zm_prod: "https://zapmeet.app/book/N8s_OGaZrHu_rrBvYDjcP?sthb=true",
  };

  $: src =
    route.page == "buy"
      ? srcs.buy_zm_prod
      : route.page == "book-disco"
      ? srcs.book_disco_zm_prod
      : srcs.book_zm_prod;

  let iframe;
  $: height = 1000;

  function minHeight() {
    if (!iframe) return 0;

    return (
      window.innerHeight - iframe.getBoundingClientRect().top - window.scrollY
    );
  }

  function onMessage(event) {
    if (event.data.type == "zm-resize") {
      height = Math.max(minHeight(), event.data.height);
    }
  }

  onMount(() => {
    window.addEventListener("message", onMessage, false);
  });

  function sendHello() {
    iframe.contentWindow.postMessage({ type: "zm-hello" }, "*");
  }
</script>

{#if route.page != "book-disco"}
  <BookBuyNav page={route.page} />
{/if}

<iframe
  bind:this={iframe}
  on:load={sendHello}
  frameBorder="0"
  {height}
  {src}
  {title}
  width="100%"
/>

<style>
  iframe {
    display: block;
  }
</style>
