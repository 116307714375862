<script>
  import { onMount } from "svelte";

  export let items = [];

  let caret = true;
  let highlighted = false;
  let index = 0;
  let text = items[0];

  const animationSteps = {
    type: () => {
      const targetText = items[index];
      if (text.length == targetText.length) {
        caret = false;
        return ["highlight", 1000];
      }

      caret = true;
      text += targetText[text.length];
      return ["type", 150 - Math.random() * 100];
    },
    highlight: () => {
      highlighted = true;
      return ["delete", 250];
    },
    delete: () => {
      highlighted = false;

      index += 1;
      if (index >= items.length) index = 0;
      text = "";

      return ["type", 0];
    },
  };

  function animate(action) {
    const [nextAction, nextInterval] = animationSteps[action]();
    if (nextInterval == 0) animate(nextAction);
    else setTimeout(() => animate(nextAction), nextInterval);
  }

  onMount(() => animate("type"));
</script>

<div>
  <span class:caret class:highlighted>
    {text}
  </span>
</div>

<style>
  div {
    display: flex;
    justify-content: center;
  }

  span {
    white-space: nowrap;
  }

  span.caret {
    border-left: 3px solid transparent;
    border-right: 3px solid var(--ps-colors-dark-gold);
  }

  span.highlighted {
    background-color: #ffffffaa;
  }
</style>
