<script>
  import {
    faChevronCircleDown,
    faChevronCircleUp,
  } from "@fortawesome/free-solid-svg-icons";
  import { afterUpdate } from "svelte";
  import { airtableData } from "../airtable/compiled";
  import Container from "../components/Container.svelte";
  import Icon from "../components/Icon.svelte";
  import LogoGrid from "../components/LogoGrid.svelte";
  import Markdown from "../components/Markdown.svelte";
  import Modal from "../components/Modal.svelte";
  import NavLink from "../components/NavLink.svelte";
  import Quote from "../components/Quote.svelte";
  import Section from "../components/Section.svelte";
  import WorkshopNav from "../components/WorkshopNav.svelte";
  import { consultingPartners as partners } from "../partnerLogos";
  import { undrawImageAlt } from "../undrawImageAlts";

  export let id;

  $: testimonials = airtableData.testimonials.filter(
    (t) => t.pagePaths[0] == `/w/${id}`
  );

  $: if (testimonials.length == 0) {
    throw new Error(`workshop ${id} has no testimonials`);
  }

  const workshopsByStub = Object.fromEntries(
    airtableData.workshops.map((w) => [w.stub, w])
  );
  $: workshop = workshopsByStub[id];

  const descriptionSecsById = {};
  $: if (!descriptionSecsById[id]) {
    descriptionSecsById[id] = workshop.description
      .split("!SECBREAK!")
      .map((text) => ({ text, div: null, open: false }));
  }
  $: descriptionSecs = descriptionSecsById[id];

  function toggleDescriptionSec(ds) {
    ds.open = !ds.open;
    descriptionSecs = descriptionSecs;
    updateDescriptionSecHeights();
  }

  function updateDescriptionSecHeights() {
    for (const ds of descriptionSecs) {
      if (!ds.div) continue;

      if (ds.open) {
        ds.div.setAttribute("style", `max-height:${ds.div.scrollHeight}px`);
      } else {
        const h3 = ds.div.getElementsByTagName("h3")[0];
        const maxHeight = h3 ? `${h3.scrollHeight + 10}px` : "3.1rem";
        ds.div.setAttribute("style", `max-height:${maxHeight}`);
      }
    }
  }

  afterUpdate(updateDescriptionSecHeights);

  $: program = workshop.programsS1.concat(workshop.programsS2)[0];
  $: requirement = workshop.requires[0];

  let formModalActive = false;
  let innerHeight;
  let innerWidth; // triggers afterUpdate whenever window is resized horizontally
</script>

<svelte:window bind:innerHeight bind:innerWidth />

{#if formModalActive}
  <Modal bind:active={formModalActive} spinner>
    <iframe
      src="https://airtable.com/embed/shrNKImTFjRoqNNIL?{encodeURIComponent(
        'prefill_Interested In'
      )}={encodeURIComponent(workshop.name)}"
      title="Register Interest"
      height={innerHeight - 150}
      width="100%"
    >
      Loading form...
    </iframe>
  </Modal>
{/if}

<Section>
  <Container>
    <div class="columns">
      <div class="column">
        <h1>{workshop.name}</h1>
        <p class="strapline">{workshop.strapline}</p>
        <NavLink button buttonClass="is-gold is-medium" to="/book-disco-call">
          Book a call
        </NavLink>
      </div>
      <div class="column">
        <img
          alt={undrawImageAlt(workshop.image)}
          class="adaptive-400 mb1r maxh-300"
          src="/img/illustrations/gold/undraw_{workshop.image}.svg"
        />
      </div>
    </div>
  </Container>
</Section>

<Section bgColor="dark-grey">
  <Container>
    <div class="columns">
      <div class="column is-two-thirds">
        <!-- {#if program || requirement}
          <div class="prog-req">
            {#if program && requirement}
              <p>
                This workshop is part of our <NavLink
                  style="text-decoration-line: underline;"
                  to="/p/{program.stub}">{program.name}</NavLink
                > program.
              </p>
            {:else if program}
              <p>
                This workshop is part of our <NavLink
                  style="text-decoration-line: underline;"
                  to="/p/{program.stub}">{program.name}</NavLink
                > program. It can also be taken stand-alone.
              </p>
            {/if}

            {#if requirement}
              <p>
                To take this workshop, you'll first need to take our <NavLink
                  style="text-decoration-line: underline;"
                  to="/w/{requirement.stub}">{requirement.name}</NavLink
                > workshop.
              </p>
            {/if}
          </div>
        {/if} -->
        {#each descriptionSecs as ds}
          {#if descriptionSecs.length > 1}
            <div
              bind:this={ds.div}
              class="description subsection"
              class:closed={!ds.open}
              on:click={ds.open ? null : () => toggleDescriptionSec(ds)}
            >
              <div
                class="toggle"
                on:click|stopPropagation={() => toggleDescriptionSec(ds)}
              >
                <Icon i={ds.open ? faChevronCircleUp : faChevronCircleDown} />
              </div>
              <Markdown
                content={ds.text}
                dlButtonClass="is-gold"
                dlButtonLabel="Download workshop info"
                styleStrongWhite
              />
            </div>
          {:else}
            <div class="description">
              <Markdown
                content={ds.text}
                dlButtonClass="is-gold"
                dlButtonLabel="Download workshop info"
                styleStrongWhite
              />
            </div>
          {/if}
        {/each}
        <div class="buttons">
          <NavLink button buttonClass="is-gold" to="/book-disco-call">
            Book a call
          </NavLink>
        </div>
        <WorkshopNav
          filter={(w) => w.id != workshop.id}
          forceGray
          groupFormatter={(g) =>
            `Other ${g}` + (g.includes("Working") ? "" : " Workshops")}
          groups={[workshop.category]}
          showAllWorkshops
        />
      </div>
      <div class="column no-pad">
        <div class="quotes color-bg-snow">
          {#each testimonials as t}
            <Quote
              citation={t.citationOverride || t.citation}
              paras={t.text.split("\n")}
              photo={t.image}
            />
            <!-- photoInline -->
            <!-- photoSmall -->
          {/each}
          <div class="buttons">
            <NavLink button buttonClass="is-dark" to="/why-us-training">
              Read more feedback
            </NavLink>
            <NavLink button buttonClass="is-dark" to="/how-we-work">
              Learn how we work
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  </Container>
</Section>

<Section>
  <Container>
    <LogoGrid {partners} />
  </Container>
</Section>

<!-- this is a bit of a hack - should really have indepedent control on each workshop -->
{#if workshop.category != "Team Working Sessions"}
  <Section bgColor="platinum">
    <Container>
      <p>
        In addition to offering dedicated workshops and programs for teams, we
        hold regular sessions that are open to individuals.
      </p>
      <p>
        If you'd be interested in participating as an individual, let us know...
      </p>
      <button class="button is-gold" on:click={() => (formModalActive = true)}>
        Register interest
      </button>
    </Container>
  </Section>
{/if}

{#if workshop.quote}
  <Section bgColor="jet">
    <Container>
      <Quote citation={workshop.quoteCitation} large paras={[workshop.quote]} />
    </Container>
  </Section>
{/if}

<style>
  h1 {
    font-size: var(--ps-fontsize-large);
  }

  img.maxh-300 {
    max-height: 300px;
  }

  .buttons {
    justify-content: center;
    margin: 0 !important;
  }

  .buttons :global(.button) {
    margin: 5px !important;
  }

  .column.no-pad {
    padding: 0;
  }

  .description :global(.mdown.button) {
    margin-bottom: 1rem;
    margin-top: -0.5rem;
  }

  .quotes {
    border-radius: 5px;
    padding: 1rem;
  }

  .quotes :global(.quote) {
    margin-bottom: 2rem;
  }

  .subsection {
    border: 1px solid var(--ps-colors-platinum);
    border-radius: 5px;
    margin-bottom: 1.5rem;
    overflow: hidden;
    padding: 5px 10px;
    transition: max-height 0.25s;
    width: 100%;
  }

  .subsection.closed {
    cursor: pointer;
  }

  .subsection.closed:hover {
    border-color: var(--ps-colors-gold);
    color: var(--ps-colors-gold);
  }

  .subsection .toggle {
    cursor: pointer;
    float: right;
    padding-top: 0.4rem;
  }

  .subsection .toggle:hover {
    color: var(--ps-colors-gold);
  }

  .subsection.closed .toggle {
    display: block;
  }

  .description :global(.mdown img) {
    display: block;
    margin: 0 auto;
    max-width: 500px;
    width: 100%;
  }

  .prog-req {
    border: 1px solid var(--ps-colors-gold);
    border-radius: 5px;
    margin-bottom: 1.5rem;
    padding: 10px;
  }
</style>
