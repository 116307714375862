<script>
  // export let style = "";

  export let cta = "Sign up";
  export let color = "default-color";
  export let size = "default-size";

  let encodedPathSource = "peoplestorming.com (unknown)";

  $: {
    if (typeof window !== "undefined") {
      encodedPathSource = encodeURIComponent(window.location.toString());
    }
  }

  let iframe;

  // This was reverse-engineered from https://static.airtable.com/js/embed/embed_snippet_v1.js
  // onMount(() => {
  //   function notifyEmbedViewChanged() {
  //     let b = iframe.getBoundingClientRect();

  //     iframe.contentWindow.postMessage(
  //       {
  //         key: "airtableEmbedViewportChanged",
  //         embedRectInViewport: {
  //           top: b.top,
  //           right: b.right,
  //           bottom: b.bottom,
  //           left: b.left,
  //         },
  //         embedViewportSize: {
  //           height: window.innerHeight,
  //           width: window.innerWidth,
  //         },
  //       },
  //       "*"
  //     );
  //   }

  //   window.addEventListener("resize", notifyEmbedViewChanged);
  //   window.addEventListener("scroll", notifyEmbedViewChanged);

  //   window.addEventListener("message", function (a) {
  //     if (!a.data || a.data.key != "airtableEmbedContentDidResize") return;

  //     iframe.height = a.data.height;

  //     iframe.contentWindow.postMessage(
  //       { key: "airtableDisableScrollbar" },
  //       "*"
  //     );

  //     notifyEmbedViewChanged();
  //   });
  // });
</script>

<!-- <iframe
  bind:this={iframe}
  frameborder="0"
  height="600"
  src="https://airtable.com/embed/shrnPyVT7VUkq6LCk?hide_From=true&prefill_From={encodedPathSource}" 
  style="background: transparent; {style}"
  title="Join Our Community"
  width="100%"
  /> -->

<div>
  <a
    class="button is-{color} is-{size}"
    href="https://airtable.com/shry6jQY9hyIWrjSW?hide_From=true&prefill_From={encodedPathSource}"
    rel="noopener"
    target="_blank"
  >
    {cta}
  </a>
</div>
