<script>
  import Modal from "../components/Modal.svelte";
  import NavLink from "./NavLink.svelte";

  let modalActive = false;
</script>

<Modal bind:active={modalActive}>
  <div class="details">
    <p>
      Our Adaptive Manager program is an alternative pathway for organizations
      who want to train <strong>more seasoned</strong> managers and/or
      <strong>fewer-than-6</strong> managers at a time.
    </p>

    <p>
      It builds a personalized journey starting from the same 5 core topics,
      providing...
    </p>

    <ul>
      <li>10 x 1-on-1 personalised training and coaching sessions.</li>
      <li>
        5 x small-group facilitated discussion gatherings (roundtables).<sup
          >*</sup
        >
      </li>
      <li>Access to all PeopleStorming workshop training materials.</li>
      <li>Introductions to mentors in our network.</li>
    </ul>

    <div class="buttons">
      <NavLink button buttonClass="is-gold" to="/book-disco-call">
        Book a call to get a quote
      </NavLink>
    </div>

    <small><sup>*</sup> when you ask us to train 2+ managers </small>
  </div>
</Modal>

<div class="color-bg-jet cta">
  <p>More seasoned leaders? Fewer than 6 people to train?</p>
  <button class="button is-gold" on:click={() => (modalActive = true)}>
    Adaptive program
  </button>
</div>

<style>
  div.buttons {
    justify-content: center;
  }

  div.cta {
    border-radius: 5px;
    padding: 1.5rem;
    width: fit-content;
  }

  div.details {
    text-align: left;
  }

  li {
    padding-bottom: 0.5rem;
  }

  small {
    font-size: var(--ps-fontsize-tiny);
    font-style: italic;
    opacity: 70%;
  }

  ul {
    list-style: initial;
    padding-bottom: 1rem;
    padding-left: 1rem;
  }
</style>
