<script>
  import AirtableSubscribe from "../components/AirtableSubscribe.svelte";
  import Container from "../components/Container.svelte";
  import Section from "../components/Section.svelte";

  import { undrawImageAlt } from "../undrawImageAlts";
</script>

<Section bgColor="mid-grey">
  <Container>
    <h1>Join Our Community</h1>
    <img
      alt={undrawImageAlt("newsletter_re_wrob")}
      class="head mb1r"
      height="250"
      src="/img/illustrations/gold/undraw_newsletter_re_wrob.svg"
      width="250"
    />
  </Container>
</Section>

<Section bgColor="gold">
  <Container>
    <div class="columns is-centered">
      <div class="column is-two-thirds">
        <p class="strapline">
          Get tips, tools and inspiration on
          <strong> collaboration, communication and workplace culture </strong>
          to your inbox, twice a month
        </p>
      </div>
    </div>
    <AirtableSubscribe color="dark" size="medium" />
  </Container>
</Section>
