<script>
  import BlogPostCard from "../components/BlogPostCard.svelte";
  import Container from "../components/Container.svelte";
  import Section from "../components/Section.svelte";

  import { posts } from "../blog/facade";

  const postSlices = posts(true).reduce((slices, post, i) => {
    const sliceId = Math.floor(i / 3);
    slices[sliceId] = (slices[sliceId] || []).concat([post]);
    return slices;
  }, []);
</script>

<Section>
  <Container>
    {#each postSlices as slice}
      <div class="columns">
        {#each slice as post}
          <div class="column is-one-third">
            <BlogPostCard {post} />
          </div>
        {/each}
      </div>
    {/each}
  </Container>
</Section>

<style>
  .column {
    padding: 1rem;
    text-align: left;
  }
</style>
