<script>
  import { faDiceFive, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

  import { airtableData } from "../airtable/compiled";
  import Container from "../components/Container.svelte";
  import Icon from "../components/Icon.svelte";
  import Modal from "../components/Modal.svelte";
  import NavLink from "../components/NavLink.svelte";
  import Quote from "../components/Quote.svelte";
  import Section from "../components/Section.svelte";

  // Key Values
  let mins = 15;
  let participants = 5;

  // Preparation
  let prepOwner = 100;
  let prepOther = 20;

  // Followup
  let fupOwner = 50;
  let fupOther = 10;

  // Context Switching
  let ctxMinsPre = 15;
  let ctxMinsPost = 15;

  // Frequency
  let occurence = 1;

  // Calcs
  $: ownerMins =
    (prepOwner * mins) / 100 +
    ctxMinsPre +
    mins +
    ctxMinsPost +
    (fupOwner * mins) / 100;
  $: otherMins =
    (prepOther * mins) / 100 +
    ctxMinsPre +
    mins +
    ctxMinsPost +
    (fupOther * mins) / 100;
  $: totalMins = (ownerMins + (participants - 1) * otherMins) * occurence;
  $: totalHours = Math.round(totalMins / 6) / 10;

  // Alternate Time Uses
  let costAltNow = new Date();
  let costAlt = "";
  let costAltId = "";
  $: {
    costAltNow;

    const hours = Math.max(totalHours, 1);

    const alts = airtableData.meetCostAlts.filter(
      (alt) => alt.hours <= hours && alt.id != costAltId
    );

    const alt = alts[Math.floor(Math.random() * alts.length)] || {
      id: "",
      hours: 1,
      plural: "read the Netflix culture deck # times",
      singular: "read the Netflix culture deck",
    };

    const repeats = Math.floor(hours / alt.hours);
    costAlt = repeats == 1 ? alt.singular : alt.plural.replace("#", repeats);
    costAltId = alt.id;
  }

  // Utils

  function humanize(mins) {
    if (mins < 60) return `${mins} mins`;
    let h = mins >= 120 ? "hrs" : "hr";
    return mins % 60 == 0
      ? `${Math.floor(mins / 60)} ${h}`
      : `${Math.floor(mins / 60)} ${h}, ${mins % 60} mins`;
  }

  let modal = null;
  const modals = {
    key: {
      title: "Key Values",
      mainText: "Start by setting these key meeting values.",
      asides: [],
    },
    ctxt: {
      title: "Context Switching",
      mainText:
        "People need time to pause what they're doing beforehand. Afterwards, they have to get back up to speed.",
      asides: [
        "On average, it takes around 15 minutes to do each of these properly.",
        "Highly predictable work (like call center ops) may need less time.",
        "Highly ambiguous work (like software engineering) may need more.",
      ],
    },
    prep: {
      title: "Preparation",
      mainText:
        "The meeting owner will need to compose invites, write an agenda and prepare other materials. Other participants will need to read those items and prepare their thoughts.",
      asides: [
        "For worthwhile meetings, prep time tends to scale with the meeting's duration.",
        "We usually assume 100% for the meeting owner and 20% for everyone else.",
      ],
    },
    fup: {
      title: "Followup",
      mainText: "The admin and other action items arising from the meeting.",
      asides: [
        "For worthwhile meetings, followup time tends to scale with the meeting's duration.",
        "We usually assume 50% for the meeting owner and 10% for everyone else.",
      ],
    },
  };
  let modalActive = false;
  function showModal(k) {
    modal = modals[k];
    modalActive = modal != null;
  }
</script>

<datalist id="ctxt-switches">
  {#each Array.from({ length: 6 }, (_, i) => i * 5 + 5) as p}
    <option value={p} />
  {/each}
</datalist>

<datalist id="percentages">
  {#each Array.from({ length: 10 }, (_, i) => i * 10 + 10) as p}
    <option value={p} />
  {/each}
</datalist>

<Modal bind:active={modalActive}>
  {#if modal}
    <h3>{modal.title}</h3>
    <p>{modal.mainText}</p>
    {#each modal.asides as aside}
      <aside>{aside}</aside>
    {/each}
  {/if}
</Modal>

<Section bgColor="dark-grey">
  <Container>
    <h1>Meeting Cost Calculator</h1>
    <div class="header">
      <p>Choose a meeting on your calendar.</p>
      <p>Plug in the details to see its total time cost.</p>
      <p>
        Hit the <Icon i={faInfoCircle} /> symbols for guidance.
      </p>
    </div>
  </Container>
</Section>

<Section>
  <Container>
    <div class="tool">
      <div class="subsection">
        <h3>
          Key Values <Icon onClick={() => showModal("key")} i={faInfoCircle} />
        </h3>
        <div class="variables">
          <label>
            <span>Duration</span>
            <div class="value">
              <select bind:value={mins}>
                {#each [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 90, 120, 150, 180, 210, 240, 300, 360, 420, 480] as m}
                  <option value={m}>{humanize(m)}</option>
                {/each}
              </select>
            </div>
          </label>
          <label>
            <span>Participants <small>(incl. owner)</small></span>
            <div class="value">
              <select bind:value={participants}>
                {#each [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 25, 30, 35, 40, 50, 60, 70, 80, 90, 100] as p}
                  <option value={p}>{p}</option>
                {/each}
              </select>
            </div>
          </label>
        </div>
      </div>

      <div class="subsection">
        <h3>
          Context Switching
          <Icon onClick={() => showModal("ctxt")} i={faInfoCircle} />
        </h3>
        <div class="variables">
          <label>
            <span>Pre-meeting Pause</span>
            <div class="value">
              <input
                type="range"
                step="5"
                min="5"
                max="30"
                list="ctxt-switches"
                bind:value={ctxMinsPre}
              />
              {humanize(ctxMinsPre)}
            </div>
          </label>
          <label>
            <span>Post-meeting Resume</span>
            <div class="value">
              <input
                type="range"
                step="5"
                min="5"
                max="30"
                list="ctxt-switches"
                bind:value={ctxMinsPost}
              />
              {humanize(ctxMinsPost)}
            </div>
          </label>
        </div>
      </div>

      <div class="subsection">
        <h3>
          Preparation
          <Icon onClick={() => showModal("prep")} i={faInfoCircle} />
        </h3>
        <div class="variables">
          <label>
            <span>Owner</span>
            <div class="value">
              <input
                type="range"
                step="10"
                min="10"
                max="100"
                list="percentages"
                bind:value={prepOwner}
              />
              {prepOwner}% of duration
            </div>
          </label>
          <label>
            <span>Others</span>
            <div class="value">
              <input
                type="range"
                step="10"
                min="10"
                max="100"
                list="percentages"
                bind:value={prepOther}
              />
              {prepOther}% of duration
            </div>
          </label>
        </div>
      </div>

      <div class="subsection">
        <h3>
          Followup
          <Icon onClick={() => showModal("fup")} i={faInfoCircle} />
        </h3>
        <div class="variables">
          <label>
            <span>Owner</span>
            <div class="value">
              <input
                type="range"
                step="10"
                min="10"
                max="100"
                list="percentages"
                bind:value={fupOwner}
              />
              {fupOwner}% of duration
            </div>
          </label>
          <label>
            <span>Others</span>
            <div class="value">
              <input
                type="range"
                step="10"
                min="10"
                max="100"
                list="percentages"
                bind:value={fupOther}
              />
              {fupOther}% of duration
            </div>
          </label>
        </div>
      </div>
    </div>

    <div id="result" class="color-bg-dark-grey">
      <div class="intro">
        A
        <select bind:value={occurence}>
          <option value="1">single</option>
          <option value="260">daily</option>
          <option value="52">weekly</option>
          <option value="26">bi-weekly</option>
          <option value="12">monthly</option>
          <option value="4">quarterly</option>
        </select>
        occurence of this meeting will cost...
      </div>

      <div class="value">
        {#if totalHours <= 1}
          <div class="num">{totalMins}</div>
          <div class="units">
            person-minutes {#if occurence > 1}per year{/if}
          </div>
        {:else}
          <div class="num">{totalHours}</div>
          <div class="units">
            person-hours {#if occurence > 1}per year{/if}
          </div>
        {/if}
      </div>

      <div class="could">
        <p>
          In that time, you could <strong>{costAlt}</strong>
        </p>

        <button
          aria-label="Roll another example"
          class="button is-dark"
          on:click={() => (costAltNow = new Date())}
        >
          <Icon i={faDiceFive} transSize={25} />
        </button>
      </div>
    </div>

    <div class="cta">
      <p>
        This is one of many tools we've developed as part of our <NavLink
          to="/w/fix-your-meetings"
        >
          Fix Your Meetings
        </NavLink>
        workshop.
        <!-- <br />
        We wrote more deeply about it in a <NavLink to="/blog"
          >blog post</NavLink
        >. -->
      </p>
      <p>If you're stressed by dysfunctional meeting culture...</p>
      <p>
        <NavLink button buttonClass="is-gold is-medium" to="/book-disco-call">
          Book a call
        </NavLink>
      </p>
    </div>
  </Container>
</Section>

<Section bgColor="jet" noPad>
  <Container>
    <div class="columns is-centered">
      <div class="column is-two-thirds">
        <Quote
          citation="Thom - Product Director"
          paras={[
            "There are lots of things that I took for granted would not or could not be changed about our meetings. This course changed my thinking. I particularly liked the hands-on approach, rather than having it be a lecture. It was lots of fun and very informative.",
          ]}
        />
      </div>
    </div>
  </Container>
</Section>

<style>
  .header p {
    margin: 0;
  }

  .header p :global(.icon) {
    font-size: medium;
    vertical-align: bottom;
  }

  .tool {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: center;
  }

  .subsection {
    background: var(--ps-colors-snow);
    border: 1px gray dotted;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-width: 300px;
    padding: 1rem;
  }

  .subsection h3 {
    align-items: center;
    display: flex;
    gap: 0.5rem;
    margin: 0;
  }

  .subsection h3 :global(.icon) {
    font-size: medium;
    opacity: 70%;
  }

  .subsection h3 :global(.icon):hover {
    cursor: pointer;
    opacity: 100%;
  }

  aside {
    font-size: smaller;
    font-style: italic;
  }

  .subsection .variables {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  label {
    align-items: baseline;
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
  }

  label input {
    width: 100%;
  }

  label span {
    font-weight: bold;
  }

  label .value {
    align-items: baseline;
    display: flex;
    flex-direction: column;
    font-size: smaller;
    gap: 0.1rem;
    width: 250px;
  }

  label .value select {
    margin-top: 0.2rem;
  }

  #result {
    border: 3px solid var(--ps-colors-gold);
    border-radius: 5px;
    display: inline-flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 2rem;
    min-width: 300px;
    padding: 1rem 2rem;
  }

  select {
    background-color: transparent;
    border-color: gray;
    border-radius: 5px;
    color: inherit;
    font-size: 1em;
  }

  #result .value {
    color: var(--ps-colors-light-gold);
    font-weight: bold;
  }

  #result .value .num {
    font-size: 300%;
    line-height: 1;
  }

  #result .could p {
    margin-bottom: 0.5rem;
  }

  #result .could button {
    border: 1px dotted grey;
  }

  .cta {
    background-color: var(--ps-colors-platinum);
    border-radius: 5px;
    display: inline-flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
    padding: 1rem 2rem;
  }

  .cta :global(a):not(.button) {
    text-decoration-line: underline;
  }

  .cta p {
    margin: 0;
  }
</style>
