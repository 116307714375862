<script>
  import { airtableData } from "../airtable/compiled";
  import Container from "../components/Container.svelte";
  import NavLink from "../components/NavLink.svelte";
  import Section from "../components/Section.svelte";
  import { undrawImageAlt } from "../undrawImageAlts";

  export let id;

  $: upgrade = airtableData.upgrades.find((u) => u.stub == id);

  $: workshops = airtableData.workshops.filter((w) =>
    upgrade.workshops.includes(w.stub)
  );
</script>

<Section bgColor="light-gold">
  <Container>
    <h1>
      Upgrade your team's {upgrade.stub}
    </h1>
    <p class="subtitle">
      with live, interactive, bitesize, virtual workshops<br />
      hosted by experienced leaders
    </p>
    <NavLink button buttonClass="is-dark is-medium" to="/book-call">
      Book a call
    </NavLink>
  </Container>
</Section>

<Section bgColor="dark-grey">
  <Container>
    <div class="columns is-multiline">
      {#each workshops as w}
        <div class="column is-one-third">
          <div class="card">
            <figure>
              <img
                alt={undrawImageAlt(w.image)}
                src="/img/illustrations/gold/undraw_{w.image}.svg"
              />
            </figure>
            <h3>
              {w.name.split(" Workshop")[0]}
            </h3>
            <p>{w.summary}</p>
            <div />
            <NavLink button buttonClass="is-gold" to="/w/{w.stub}">
              Explore this workshop
            </NavLink>
          </div>
        </div>
      {/each}
    </div>
  </Container>
</Section>

<style>
  h1 {
    font-weight: bold;
  }

  p.subtitle {
    font-size: var(--ps-fontsize-medium);
  }

  .card {
    background-color: var(--ps-colors-mid-grey);
    border-radius: 5px;
    color: white;
    padding: 1rem;
  }

  .card :global(.button) {
    margin-bottom: 0.5rem;
  }

  .card figure {
    background: var(--ps-colors-snow);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin: -1rem;
    margin-bottom: 1.5rem;
    padding: 1rem;
  }

  .card img {
    height: 150px;
  }
</style>
