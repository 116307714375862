<script>
  import { faStar } from "@fortawesome/free-solid-svg-icons";

  import Container from "../components/Container.svelte";
  import Icon from "../components/Icon.svelte";
  import NavLink from "../components/NavLink.svelte";
  import Quote from "../components/Quote.svelte";
  import Section from "../components/Section.svelte";
  import ToolkitCarouselCard from "../components/ToolkitCarouselCard.svelte";
  import { slices } from "../utils";

  import { airtableData } from "../airtable/compiled";
  import { undrawImageAlt } from "../undrawImageAlts";

  $: tSlices = slices(
    airtableData.tools.toSorted((a, b) =>
      (b.link1 || "").localeCompare(a.link1 || "")
    ),
    4
  );
</script>

<Section bgColor="dark-grey">
  <Container>
    <img
      alt={undrawImageAlt("remotely_2j6y")}
      class="head mb1r"
      height="779"
      src="/img/illustrations/gold/undraw_remotely_2j6y.svg"
      width="882"
    />
    <h1>How We Work</h1>
    <NavLink button buttonClass="is-gold is-medium" to="/book-call">
      Book a call
    </NavLink>
  </Container>
</Section>

<Section noPad>
  <Container>
    <div class="columns">
      <div class="column">
        <Icon i={faStar} gold large transSize={20} />
        <h3>Our Approach</h3>
        <ul>
          <li>Contextual Learning</li>
          <li>Explain &middot; Demonstrate &middot; Practice</li>
          <li>Reflection & Mindfulness</li>
          <li>Infuse Lightness & Humor</li>
          <li>Dialogue Over Monologue</li>
        </ul>
      </div>
      <div class="column">
        <Icon i={faStar} gold large transSize={20} />
        <h3>Our Expertise</h3>
        <ul>
          <li>Behavioral Science</li>
          <li>Agile Methods</li>
          <li>Design Thinking</li>
          <li>Structural Dynamics</li>
          <li>Improv & Playback Theater</li>
        </ul>
      </div>
      <div class="column">
        <Icon i={faStar} gold large transSize={20} />
        <h3>Our Ingredients</h3>
        <ul>
          <li>Research-based Content</li>
          <li>Workplace Tested Approaches</li>
          <li>Participatory & Experiential Learning</li>
          <li>Interactive Activities</li>
          <li>Engaging Games</li>
        </ul>
      </div>
    </div>
  </Container>
</Section>

<Section bgColor="jet">
  <Container>
    <Quote
      citation="Sam Kaner"
      large={true}
      paras={[
        "The facilitator's job is to support everyone to do their best thinking.",
      ]}
    />
  </Container>
</Section>

<Section>
  <Container>
    <h2>Our Toolkit</h2>
    <!-- <Carousel component={ToolkitCarouselCard} items={airtableData.tools} /> -->
    {#each tSlices as slice}
      <div class="columns">
        {#each slice as item}
          <div class="column is-one-quarter tool">
            <ToolkitCarouselCard {item} />
          </div>
        {/each}
      </div>
    {/each}
  </Container>
</Section>

<style>
  li {
    padding-bottom: 1rem;
  }

  .tool {
    border-bottom: 1px dotted gray;
  }
</style>
