<script>
  import NavLink from "./NavLink.svelte";

  import { undrawImageAlt } from "../undrawImageAlts";

  export let item;
</script>

<img
  alt={undrawImageAlt(item.image)}
  class="mb1r"
  src="/img/illustrations/gold/undraw_{item.image}.svg"
  style="height:100px"
/>
<h3>{item.name}</h3>
{#if item.link1}
  {#if item.link1.startsWith("/")}
    <NavLink button buttonClass="is-dark" to={item.link1}>See examples</NavLink>
  {:else}
    <a class="button is-dark" href={item.link1} rel="noopener" target="_blank">
      See examples
    </a>
  {/if}
{/if}
